.userProfile{

 height: 100%;
}
.auth p{
    font-size: 24px;
    font-weight: 700;
}
.usrTabs{
    margin-bottom: 2em;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.selectedBranches{
    margin: 0 10px;
}
.userProfile .loader{
    margin-right: 264px;
}
.userProfileEn .loader{
    margin-left: 264px;
    margin-right: 0 !important;

}
.navbarTopProfile{
    justify-content: end;
}
.usrTabs p{
    align-items: center;
    border-bottom: 2px solid transparent;
    color: #AFB1B6;
    cursor: pointer;
    display: inline;
    display: flex;
    font-family: Cairo;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    display: inline-block;
    letter-spacing: -.3px;
    line-height: 27px;
    margin-bottom: 0;
    margin-left: 20px;
    padding:0.5em;
}
.contd{
white-space: nowrap;
    border-radius: 4px;
border: 1px solid var(--e-47100, #E47100);
color: #E47100;
font-weight: 700;
padding: 6px 17px;
margin-bottom: 0.5em;
display: flex;

align-items: center;
background-color: white;

}
.formTopper{
 background-color:    #FFF7F1;
 padding: 16px;
 height: 100px;
 text-align: start;
 position: relative;
margin-bottom: 0;
}
.delegates{
    margin-top: 80px;
}
.userDataExt .auth{
margin-bottom: 0 !important;

}

.delegateContainer{
    border-radius: 12px;
border: 1px solid #E47100;
background: #FFF;
box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.12);
padding: 24px;
}
.delegateTitle{
    color:  #344054;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 20px; 
}
.authTitle{
margin: 0;
margin-bottom: 16px;
}
.delegateData{
    color:  #98A2B3;

font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px;
}
.addressContainer{
    border-radius: 6px;
   padding: 25px;
background: #FFF;
box-shadow: 1px 1px 5px 0px rgba(46, 46, 46, 0.20);
}
.delegateContainer div{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.uploadInp button:disabled{
    border: none;
   background-color: #98A2B3 !important
}
.uploadInp svg{
    margin-top: 0 !important;
}
.formTopper p{
    color:  #E47100;
position: absolute;

bottom: -25px;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -0.3px
}
.contd svg{
    margin: 5px;
}
.inputDiv .uploadInp {
    width: 100%;
}
.inputDiv .uploadInp input{
    width: 100%;
    padding:    10px 0.5em;
}
.usrTabs p:hover{
    border-color: #e47100!important;
    border-width: 2px;
    color: #e47100!important;
}
.usrTabs .activeTab{
    border-color: #e47100!important;
    border-width: 2px;
    color: #e47100!important;
}

.upBody{
flex:3;
padding: 2em;
margin-bottom: 100px;
}
.upLinks a{
    text-decoration: none;
}
.chargeWallet {
    background-color: #f4f4f4;
    margin-bottom: 5em;
    margin-top: 3em;
}
.cpPayment {
    background: #fff;
    border-radius: 6px !important;
    box-shadow: 4px 5px 30px rgb(0 0 0 / 10%);
    padding: 25px !important;
padding-top: 18px !important;}
.cpMiddle-title2 {
    color: #331900;
    font-family: Cairo;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -.3px;
    line-height: 45px;
    margin-bottom: 1.5em;
}
.cpMiddle-title2 {
    color: #331900;
    font-family: Cairo;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -.3px;
    line-height: 45px;}
.upTable{
    background: #FFFFFF;
    box-shadow: 4px 5px 30px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 1em 0;
    }
    .upTable-title{
        font-family: 'Cairo';
font-style: normal;
font-weight: 700;
text-align: start;
font-size: 22px;
line-height: 45px;
letter-spacing: -0.3px;
color: #331900;
    }
    .table{
        --bs-table-striped-bg: #FBFBFB !important;
    }
    .table tr { 
        line-height: 50px !important;
     }


     .table th { 
        font-family: 'Cairo' !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 16px !important;
        line-height: 30px !important;
  text-align: center !important;
        letter-spacing: -0.3px !important;
    color: #633235 !important;
    
    }
  
    .table td { 
        font-family: 'Cairo' !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 16px !important;
        line-height: 30px !important;
  text-align: center !important;
        letter-spacing: -0.3px !important;
    color: #828282 !important;

    }
   
    .table button { 
        font-family: 'Cairo';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 30px;
        letter-spacing: -0.3px;  color: #E47100;
        background: #FEF1E4;
border-radius: 24px;
border: none;
width: 100%;
padding: 0.5em 0;
white-space: nowrap;
    }

    .table button:hover { 
        background: #fbd1a6;
    }
    .invoiceTable th{
        text-align: start !important;
      }
      .invoiceTable td{
        text-align: start !important;
      }
.complaints th{
    white-space: nowrap;
}

.userProfileEn .upSideNave{
    right: auto !important;
    left: 0;
}
.userProfileEn .addrssAdd .modal-content{
    left: auto !important;
}
.upLinks svg{
    margin-left: 10px;
}
.upLinksEn svg{
    margin-right: 10px;
    margin-left: 0 !important;

}
.userProfileEn .profileBody{
    margin-left: 264px ;
    margin-right: 0 !important;
}
.upSideNaveEn{
right: auto !important;
left: 0;
}
.userDataExt input[type="date"]::-webkit-inner-spin-button,
.userDataExt input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    width: 100%;
    position: absolute;
    right: 0;
    top: 0;
  
}
.regStepsContainer{
    padding: 22px;
    background: white;
    border-radius: 12px;
    border-radius: 6px;
    box-shadow: 1px 1px 5px 0px rgba(46, 46, 46, 0.20);
}

.regStepsContainer .userDataExt{
box-shadow: none;
padding: 0 !important;
}
.regTitle{
    color:  #AFB1B6;
align-items: center;
font-weight: 700;
line-height: 24px; 
display: flex;
margin-bottom: 54px;
}
.regStep2Auth{
width: 100% !important;
    margin-top: 42px !important;
    margin-bottom: 18px !important;
}
.regStep2Auth .authTitle{
margin-bottom: 0 !important;
}
.addDoc textarea{
    width: 100%;
    height: 120px ;
}
.addDoc .logoutModal p{
    color: var(--gray-800, #1D2939);
text-align: start;
font-family: Cairo;
font-size: 36px;
}
.addDoc input, .addDoc textarea{
    color:  #667085;
    padding: 16px;
text-align: start;
font-family: Cairo;
font-size: 16px;
border-radius: 8px !important;
border: 1px solid var(--gray-300, #D0D5DD) !important;
}

.addDoc label{
    text-align: start;
    color:  #344054;
    font-family: Cairo;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}
.authTop div{
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;}
.authTop .authTitle{
    color: rgba(99, 50, 53, 0.74) !important;
margin-bottom: 0 ;
    font-size: 18px !important;
}
.nextStep2 button{
    width: 120px;
    margin-top: 56px;
  
}
.addAuth{
    border-radius: 6px;
    border: none;
    background-color: #e47200;
    color: white;
    padding:  10px 20px;
    display: flex;
    align-items: center;
    white-space: nowrap;
}
.addAuth:hover{
    background-color: #f17f0d;
 
}
.addAuth svg{
    margin: 0 5px;
    fill: white;
}
.addAuth path{
    stroke: white;

}
.regTitle a{
    text-decoration: none;
}
.regTitle p{
    border-bottom: 2px solid transparent;
    cursor: pointer;
    margin-bottom: 0;
}
.regTitle p:hover{
    color:  #E47100;
    border-bottom: 2px solid #E47100;

}
.regTitleActive{
    color:  #E47100 !important;
    border-bottom: 2px solid #E47100 !important;

font-weight: 700;
line-height: 24px; 
}
.userDataExt input[type="date"]{
    text-align: end;
    position: relative;
}
.registerEn .dateSvg{
    right: 10px;
    left: auto !important;

}
.registerEn .userDataExt input[type="date"]{
    text-align: start !important;
}
.dateSvg{
    position: absolute;
    left: 10px;
    top: 13px;
    pointer-events: none;
}
.upSideNave{
    flex:1;
    background:  #633235;;
    box-shadow: 4px 5px 30px rgba(0, 0, 0, 0.1);
  height: 100%;
right: 0;
 
  
position: fixed;
top: 0;

   
    text-align: center;


    display: flex;
    flex-direction: column;


 
    align-self: flex-start; 
    width: 264px;
}
.usrNavLogo{
    background-color: #24242456;
  margin-bottom: 36px;
    padding:10px 37px ;
}
.usrNavLogo div{
    
    height: 44px;
    width: 189px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.upImageBorder{
    border-radius: 50%;
}
.upTop{
    display: flex;
    justify-content: start;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    text-align: start;
  
    padding-bottom: 0;
}

.upImageBorder .profileImage{
    background-size: cover;
    background-repeat: no-repeat;
    background-position:  center;

    border-radius: 100px;
    width: 105px;
height: 105px;
    max-width: 200px;
    max-height: 200px;
}


.upName{
    
    font-family: 'Cairo';
font-style: normal;

font-size: 24px;
line-height: 20px;
font-style: normal;
font-weight: 600;
letter-spacing: -0.3px;
margin: 8px 0;
color: #FFFFFF;
}

.upPhone{
    font-family: 'Cairo';
font-style: normal;

font-size: 14px;
font-style: normal;
font-weight: 500;

letter-spacing: 0.7px;

color: white;

}

.upLinks{
 
    font-family: 'Cairo';
padding:30px 12px;
text-align: initial;


}
.upLinks p{
    margin-bottom: 12px;
    padding: 18.5px  15px;
}
.upLinks p:hover{
   
    border-radius: 6px;
font-weight: bold !important;
    background:  #24242432;
    

}
.upLinksEn p:hover{
    border-radius: 6px;
 
    background:  #24242432;
    



}



.upLinksActive {
   
    border-radius: 6px;
    font-weight: bold !important;

    background:  #24242432;
    }

    .upLinksEn .upLinksActive{
        border-radius: 6px;
 
        background:  #24242432;
    
    }

.userData{

    text-align: center;
    padding: 3em 0;
    background: #F4F4F4;
    border-radius: 12px;

}
.addressTitle{
    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 24px;
color:#E47100;
margin-top: 45px;

}
.passChange label{
  
font-size: 14px !important;
}
.passChange .flexForm{
    display: flex;
  
    justify-content: space-between;
}
.userData label{

margin-top: 1em;
    display: block;

font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 16px;

margin-bottom: 0.5em;
letter-spacing: -0.3px;

color: #4F4F4F;

   }
 
.mapAlign{
    display: flex;
    justify-content: center;
    align-items: center;
}
.inputDiv a{
cursor:pointer;
}
.inputDiv a svg{
    margin-top: 0.5em;
}
.inputDiv a path{
stroke: #b30c00;
}
   .userAddAddress{

    background: #F4F4F4;
border-radius: 12px;
padding: 3em;
   }

   .userAddAddress input{
    background: #FFFFFF;
    border-radius: 8px;

   }
   #pass3{
    padding: 7px 1em;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 3px;
    color: #ACACAC;
   }
   .passChange .eye{
       top: 13px !important;
   }

 .userData  .inputDiv{

width: 55%;
margin: auto;
text-align: start;
 }
   .userData input{
    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 30px;

letter-spacing: -0.3px;

color: #633235 ;
    border-radius: 8px;
    letter-spacing: -0.3px;
    border: none;
   
 width: 100%;
    padding: 0.5em;
}

.imgIcn path{
stroke: white !important;
}
.changePass{
    background-color: transparent;
    border: none !important;
    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 31px;
margin-top: 0.5em;
letter-spacing: -0.3px;
text-decoration-line: underline;
margin-top: 35px;
color: #E47100;


}

.dataEdit{
    background: #E47100;
border-radius: 8px;
border: none;

font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 37px;

letter-spacing: -0.3px;

color: #FFFFFF;
width: 120px;
}

.formBtns{

    margin-top: 0em;
    text-align: end;
   
}

.formBtns span{


display: inline !important;
margin: 0 1em !important;
}
.cancelEdit{
width: 45%;
color:#AFB1B6;
border: none;
    border-radius: 8px;
background-color: transparent;
font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 37px;

letter-spacing: -0.3px;

background: rgba(216, 216, 216, 0.50);;

}
.formBtns .cancelEdit{
    width: 120px;
}
.cancelEdit:hover{
    background: rgba(216, 216, 216, 0.675);;
    color:#AFB1B6;

}
.userDataExt .inputDiv .eye{
    top: 15px;
}
.contReg{
    display: flex;
    align-items: center;
    justify-content:end;
    margin-bottom: 32px;
    padding: 0 22px;
    }
    .verify{
        display: flex;
        align-items: end;
       
      }
      .verify button{
        width: 150px;
      }
      .clientCode{
        text-align: start;
        margin-bottom: 2em;
    }
    .clientCode label{
        margin: 0.5em 0;
        margin-top: 1em;
    }
    .mobile-upLinks a{
        text-decoration: none;
    }
    .clientCode input{
        width: 50% !important;
   
        padding: 1.2em 0.8em;
        border-radius: 8px;
        border: 1px solid #d4d2d2;
        display: block;

    }
    .otp div{
      width: 250px;
            }
          
             .otp input{
             padding: 1.2em 0 !important;
             width: 80% !important;
                     }
   

.editImage{
   
    background: transparent;
    border: none;
    color:  #ACACAC;
    position: absolute;
    left: -35px;
}

.userData .upImageBorder{
    padding: unset;
    border-color: transparent;
}
.userData input{
    background-color: white;
}
.profileBody{
    margin: 0;
margin-right: 264px;
padding: 24px;
width: auto;
}
.userData .profileImage{
   width: 13vw;
  height: 13vw;
  min-height: 90px;
  min-width: 90px;
}

.userData span{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: rgb(193, 22, 22);
    display: block;
margin: 0.2em 0;
   }
   .userData select{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    background: #FFFFFF;
    border-radius: 8px;
    letter-spacing: -0.3px;
    border: none;
    color: #ACACAC;
 width: 100%;
    padding: 0.5em;

   }

.upLinks p{
    color: #D0C1C2;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
cursor: pointer;}
/* complaints*/
.table2  th {
    text-align: right !important;
    padding-right: 2em !important;
}
.table2  td {
    text-align: right !important;
    padding-right: 2em !important;
}
.table2  td {
    text-align: right !important;
    padding-right: 2em !important;
    
}
.table2 tbody tr{ 
    height: 100px !important;
 }
 /* transactions*/

 .table2 button{ 
    padding: 0.7em 0 !important;
 }

 tr:nth-child(even) .transaction{
   color: #27AE60 !important;
  
    }
    tr:nth-child(odd) .transaction{
        color:  #DB095B !important;
       
         }
         .transactionCredit{
            position: relative;
            background: #FFC58B;
            border-radius: 12px 12px 0px 0px;
          
            padding:16px 32px;
          
            text-align: start;
         
         }
         .tc-p1{
            font-family: 'Cairo';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
          
            color: white !important;
            margin-bottom: 0;
         }
         .tc-p22{
            font-family: 'Cairo';
            font-style: normal;
            font-weight: 700;
            font-size: 64px;
          
            letter-spacing: -0.3px;
            color: white !important;
            display: inline-block;
            margin-bottom: 0 !important;
            margin-top: 0 !important;
            line-height: 78px;
         }
         .tc-p3{font-family: 'Cairo';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 30px;
   letter-spacing: -0.3px;
  color: #633235;
display: inline-block;
margin: 0.5em;
margin-bottom: 0;}

.tc-img{

}
.tc-img img{
   position: absolute;
   bottom: 0px;
   left: 0;
}
.tc-bg{
  
    bottom:-5px !important;
}



@media screen and (max-width: 993px) { 
    
    .tc-img img{
     
        top: 47px ;
       
     }
}

/* Address*/

.upTable-button{
    white-space: nowrap;
    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 18px;

letter-spacing: -0.3px;
color: #E47100;
background: transparent;
border: 1px solid #E47100;
border-radius: 10px;
padding: 9px 1em;
display: flex;

align-items: center;
}
.upTable-button svg{
    margin: 0 5px;
}

.upTable-button:hover{
color: white;
background-color: #E47100;
}

.upAddress{
 
    display: flex;
    justify-content: end;
    margin-bottom: 2em;
}

.addAddress{
    background: #FFFFFF;
    box-shadow: 4px 5px 30px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
padding: 2em;

}
.addAddress div{
    width: 80%;
    margin: auto;
}
.addAddress textarea{
    width: 100%;
    display: inline-block;

    background: #FFFFFF;
    border-radius: 8px;
    font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 16px;
border: 1px solid #e5e5e5;

letter-spacing: -0.3px;

color: #ACACAC;
padding: 0.4em 1em;
margin-bottom: 1em;
}


.addAddress textarea:focus{
    outline: none !important;

    border: 2px solid #e47100 !important;
}
.addAddress p{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
  
    letter-spacing: -0.3px;
    color: #331900;
    margin-bottom:1;
    text-align: start;
}
.addAddress button{
    background: transparent;
    border: none;
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    border-radius: 8px;
    letter-spacing: -0.3px;
    background-color: #E47100;
    color: white;
    width: 30%;
    padding: 0.5em 0;
}

.addAddress button:hover{
    background-color: #eb7e11;
}
.upAdressCard{
    background: #FFFFFF;
box-shadow: 4px 5px 30px rgba(0, 0, 0, 0.1);
border-radius: 0 0 12px 12px;
position: relative;
text-align: start;
}
.upAdressCard .upAdressCard-div{
    display: flex;
    justify-content: space-between;
    align-items: center;

}
.upAdressCard-div button{
    border: none;
    background: transparent;
}
.upAdressCard-div{
}
.upAdressCard p{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
  
    letter-spacing: -0.3px;
    color: #828282;
    margin-bottom: 0;

 
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

}
.upAdressCard-p1{
    font-weight: 700 !important;
    color: #331900 !important;
    height: auto !important; 
}
.upAdressCard-p2{
    font-weight: 400 !important;

color: #331900 !important;
}

.upAdressCard-btns{
position: absolute;
bottom: 0;
}
.upAdressCard-btns p{
    display: inline-block;
}
.upAdressCard-btns button{

   background: transparent;
border: none;
font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 30px;

letter-spacing: -0.3px;
color: #E47100;
}

.upAdressCard-btns button:hover{

    color: #ff8307;
}
.upAdressCard-btns span{
    margin:0 10px;
}
.upAdressCard-btns p{
    border-left: 1px solid #BEBEBE;
    display: inline-block;
}
.upAdressCard-btn1{
    border-radius: 4px;
border: 1px solid var(--e-47100, #E47100);
padding: 9px 25px;
justify-content: center;
width: 150px;
background: #E47100 !important;
box-shadow: 0px 2px 4px 0px rgba(89, 89, 89, 0.11);
color: white;
display: flex;
align-items: center;
}
.upAdressCard-btn1 svg{
    margin: 0 5px;
}
.upAdressCard-btn1:hover{
background: #eb7805 !important;
}
.mobile-upSideNav{
    padding: 2em;

}
.mobile-upSideNav-top{
    position: relative;
    margin-bottom: 176px;
}
.positionProfile{
    position: absolute;
    top: 33px;
    width: 100%;
}
.mobile-upSideNav img{
  
   width: 130px;
   height: 130px;
  }
  .upInfo{

    margin: 0 1em;
  }
.upInfo-name{
    font-family: 'Cairo';
font-style: normal;
font-weight: 900;
font-size: 32px;
line-height: 60px;
letter-spacing: -0.3px;
color: #331900;
margin-bottom: 0;
} 
.upInfo-number{
    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 37px;

letter-spacing: 0.7px;
color: #BEBEBE;
}

  .mobile-upSideNave div{
   display: inline-block;
   }

   .mobile-upLinks span{
    margin:0 16px;
    padding: 0.5em 0;
   }
   .mobile-upLinks{
       text-align: start;
   }
   .mobile-upLinks div{
    white-space: nowrap;
   overflow: auto;
    /*
       text-align: start;
       display: flex;
       justify-content: space-between;
       width: 100%;*/
   }
.mobile-upLinks p{
    font-family: 'Cairo';
font-style: normal;
color: var(--gray-500, #667085);
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 41px;
letter-spacing: -0.3px;
display: inline-block;
margin-bottom: 0;

cursor: pointer;
}
.mobile-upLinks div{
    border-bottom: 1px solid rgba(128, 128, 128, 0.198);
}
.mobile-upLinks p:hover{
    color: rgba(255, 126, 0, 1);
    border-bottom:  2px solid rgba(255, 126, 0, 1);
}

.mobile-upLinksActive{
    color: rgba(255, 126, 0, 1) !important;
    border-bottom:  2px solid rgba(255, 126, 0, 1);
}

.logoutModal{
    padding: 0 !important;
}
.barCode svg{
    width: 80vw;
    max-width: 400px;
 
}

.barCode {
    width: 80vw;
    max-width: 400px;
    max-height: 300px;
}
.transactionCreditEn{
    text-align: left;
}
.transactionCreditEn .tc-img img {

    right: 0;
    left: auto;
    -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.exCurrencyEn{
    margin-right: 0;
    margin-left: 0.5em;
}
.profileLoader{
    flex: 4;
}

@media screen and (max-width: 500px){
    .barCode svg{
     width: 93vw;
    }
    .barCode {
        max-height: 260px;
        width: 100%;
    }
    .decore{
bottom: -15px;
    }
}



  .mobile-upSideNav{
    display: none;
  }
    
    
   


@media screen and (max-width: 500px){
    .upInfo-name{
     font-weight: 600;
       font-size: 30px;
    }
   
    .mobile-upLinks p{
font-size: 20px;
    }
     
    .table button{
font-weight: 600;
    }
    .upTable-button{
font-size: 20px;
padding: 0 1em;
    }
    .upTable-title {
        font-weight: 600;
    }
    .userAddAddress{
padding: 1em;
    }

 .userData  .inputDiv{

    width: 85%;
    
     }
   
}
.chrgWallet{
    text-align: center;
}
.chrgWallet button{
  font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 22px;

letter-spacing: -0.3px;
color: #E47100;
background: transparent;
border: 1px solid #E47100;
border-radius: 10px;
padding: 0.3em 1em;
display: inline-block;

align-items: center;
width: 30%;
min-width: 200px;
}


.chrgWallet button:hover{
color: white;
background-color: #E47100;
}
.chargeWallet .errMsg{
font-weight: 400;
font-size: 18px;
text-align: start;

}
.walletLoader{
height:6vh;
margin:auto !important;
align-items:center;
padding-top:3em;
flex-direction:column
}
.logoutImg{
    width: 145px;
    height: 145px;
    background-repeat: no-repeat;
    background-size: contain;
    margin: auto;
}
.chargeWallet input[type="number"]{
display: inline-block;

    background: #FFFFFF;
    border-radius: 8px;
    font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 16px;
border: 1px solid #e5e5e5;

letter-spacing: -0.3px;

color: #ACACAC;
padding: 0 1em;
width: 100%;
min-width: 300px;
margin-top: 1em ;
}
.modalBody p{
    color: #331900;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 0;
    font-family: Cairo;
    font-style: normal;
    font-weight: 600;

}
.logoutModal .close-modal{
    background-color: #D8D8D8;
    border-radius: 10px;
    position: static;
    box-shadow: none;
    width: 30px;
    display: block;
    height: 30px;
}
.logoutModal .close-modal svg{
    fill: #858D9B;
    width: 26px;
height: 26px;
}
.logoutModal p{
    font-weight: 700 !important;
    margin-bottom: 25px;

}
.logoutModal {
    padding: 16px 25px 56px 15px !important;
}
.confirm {
    background: #e47100;
    border: none;
    border-radius: 6px;
    color: #fff;
 white-space: nowrap;
    line-height: 34px;
    margin: 0 0.5em;
    padding: 3px 2em;
    font-family: Cairo;
    font-style: normal;
    width: 45%;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -.3px;}
.confirm:hover{
    background: #ed8115;
 
}


.cancel {
    width: 45%;
    white-space: nowrap;

    border-radius: 6px;
    background: rgba(216, 216, 216, 0.50);
    color: #AFB1B6;
    font-family: Cairo;
    font-size: 16px;
    font-style: normal;
    border: none;
    font-weight: 700;
    letter-spacing: -.3px;
    line-height: 34px;
    margin: 0 0.5em;
    padding: 3px 2em;

}
.cancel:hover {
    background: rgba(178, 176, 176, 0.649);


}
.chargeWalletBtn{
width: 100% !important;
padding: 6.5px;
}
.cpPayment .formBtns{
    text-align: center;
    margin-top: 24px !important;
    
}
.chargeWallet{
   background-color: #F4F4F4;
  margin: 0 !important;
box-shadow: none !important;
padding: 0 !important;}
.chargeWallet label{
    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 18px;

letter-spacing: -0.3px;
margin: 0 0.5em;
color: #4F4F4F;

}
.chargeWallet span{
    margin: 0 0.5em;
}
.chargeBtn{
width: 60%;
margin: auto;
    text-align: center;
    min-width: 300px;
    margin-top: 1em;
}
.chargeBtn button{
    font-family: 'Cairo';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  
  letter-spacing: -0.3px;
  color: #E47100;
  background: transparent;
  border: 1px solid #E47100;
  border-radius: 10px;
  padding:0 1em;
  display: inline-block;
  
  align-items: center;
  width: 40%;
  min-width: 150px;
  }
  
  
  .chargeBtn button:hover{
  color: white;
  background-color: #E47100;
  }
.chrgCenter{
min-width: 300px;
width:100%;
display: flex;
align-items: center;
margin:auto;
text-align:initial;

}
.customerBody p{
    font-size: 1.25rem;
    font-weight: 700;
    font-family: "Cairo";
    text-align: start;
    margin-top: 1em;
    margin-bottom: 0;
}

.contReg select{
    display: none;
}
.addressAdd p{
    text-align: center ;
}
.addressAdd{

    border-radius: 9px;
    box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;

padding: 1em;
margin-top: 1.5em;
}

.addressAdd label{
    display: block;
    text-align: start;
    font-weight: 600;
    color: #999;
    font-family: 'cairo';
    font-size: 1rem;
    margin: 1em 0;
}
.addressAdd .col-6{
 
    text-align: start;
}
.addressAdd .col-12{
 
    text-align: start;
}
.addressAdd form{
   
  
}
.addressAdd select{
    width: 100%;
 
    border-radius: 9px;
    border: none;
    background-color: #f5f2f2;
    padding: 7px;

    font-weight: 300;
    color: #633235;
    font-family: "Cairo";
}
.addressAdd{
    margin-bottom: 5em;
}
.addressAdd input{


    border-radius: 6px;
    border: 1px solid #e5e5e5;
padding: 0.5em;
    font-family: "Cairo";
    width: 100%;

}
.map2{
    width: 100%;
    height: 300px;
    margin-top: 36px;
}
.noAddress{
    padding:0 20px;
}
.uploadInpEn button{
    left: auto !important;
    right: 0.3em;
}
.addressAdd textarea{
padding: 8px;
    border-radius: 6px;
    border: 1px solid var(--neutrals-5, #D8D8D8);
    height: 80px;
background-color: white;}

    .addrssAdd .modal-content{
        left: 37% ;
    }

.addTitle{
    padding: 3px 10px;
    text-align: start !important;
    color: #e47100;
   border-bottom: 2px solid #e47100;
font-size: 18px;
width: fit-content;
margin-top: 0 !important;
padding-top: 0 !important;
margin-bottom: 19px !important;
}

.userDataExt{

    text-align: start;
    padding: 22px;
    background: white;
    border-radius: 12px;

    border-radius: 6px;
box-shadow: 1px 1px 5px 0px rgba(46, 46, 46, 0.20);

}
.userDataExt label{

    margin-top: 1em;
        display: block;
    
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    
    margin-bottom: 0.5em;
    letter-spacing: -0.3px;
    
    color: #354052;
    
       }

       .userDataExt  .auth{
         width: 93.5%;
         margin: 1em 0;
       }

   .inputDiv{

    width: 55%;
    margin: 0 !important;
    text-align: start;
     }
       .userDataExt input{
        font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    
    letter-spacing: -0.3px;
    
    color: #98A2B3 ;
    border-radius: 6px;
    border: 1px solid #D8D8D8;
        letter-spacing: -0.3px;
        
       
     width: 100%;
        padding: 8px ;
    }
   


.userDataExt .upImageBorder{
    padding: unset;
    border-color: transparent;
}
.userDataExt input{
    background-color: white;
}

.userDataExt .profileImage{
   width: 13vw;
  height: 13vw;
  min-height: 90px;
  min-width: 90px;
}
.regesterStepsContainer .selectMobile{
    display: none;
}
.userDataExt span{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: rgb(193, 22, 22);
    display: block;
margin: 0.2em 0;
   }
   .userDataExt select{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    background: #FFFFFF;
    border-radius: 8px;
    letter-spacing: -0.3px;
    border: 1px solid #D8D8D8;
    color: gray ;
 width: 100%;
    padding: 4px 8px;

   }
  


   .select-container {
    position: relative;
  }
  
  .select-container select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #ccc;
    background-color: #fff;
  }
  
  .select-arrow {
    position: absolute;
    top: 45%;
    left: 3px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    pointer-events: none;
  }
  .select-arrow svg{
    color: #ccc;
  }
  .disabledForm .inputDiv .select-arrow {display: none !important;}
  .inputDivDisabled .select-arrow {display: none !important;}
  .select-containerEn .select-arrow {
    left: auto !important;
    right: 6px;

  }
  .profileLogo{
    display: none;
}

   .disabledForm .uploadInp{
       display: none;

   }
   .selectedBranches{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #633235;
   }
   .disabledForm select, .disabledForm input{
       pointer-events: none;
       -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: none;
  background-color: transparent;
   }
   .disabledData input{
    pointer-events: none;
    -webkit-appearance: none;
-moz-appearance: none;
appearance: none;
background-image: none;
background-color: transparent;
}

   

   .disabledForm span{
    display: none;
  }
  .disabledForm label{
    font-weight: 700;
  }
  .profileReg1Ar .disabledForm input[type="date"]{
    text-align: end;
  padding: 0;
  }
  .profileReg1En .disabledForm input[type="date"]::-webkit-calendar-picker-indicator{
      left: 0;
      position: absolute;
  }
  .profileReg1En .disabledForm input[type="date"]{
    position: relative;
    padding-left: 1.2em;
  }
  .noAddress{
    color: #633235;
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    margin-top: 3em;

}
   .userDataExt  .inputDiv{

    width: 100%;
    
     }
    
     .userDataExt  .inputDiv{


        margin: auto;
        text-align: start;
         }
         .contReg .select-container, .contReg .contd{
             display: none;
         }
         .upSideNave .upTop{
             align-items: center;
         }
         @media screen and (max-width: 991px) {
            .loader{
margin: auto !important;
            }
            .navbarTopProfile{
                justify-content: space-between;
            }
            .contReg{
                margin-top: 24px;
            }
            .upInfo-name{
                color: var(--gray-900, #101828);
                text-align: right;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 32px;
                margin-top: 24px;
                margin-bottom: 0;
        
        }
        
        .upInfo-number{
            color: var(--gray-600, #475467);
        
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        }
        .brownDiv{
                background: #633235;
                height: 100px;
            }
            .upImageBorder .profileImage{
                border-radius: 200px;
        border: 4px solid var(--base-white, #FFF);
        /* Shadow/lg */
        box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
        
        
        
            }
            
            .mobile-upSideNav{
                padding: 0;
            }
            .profileLogo{
                display: block;
            }
          .profileLogo  .navLogo{
              height: 49px;
              width: 159px;
          }
            #webNavbar{
                padding: 0 !important;
            }
            .userProfileEn .profileBody,.profileBody{
                margin: 0 !important;
                margin-bottom: 2em !important;
                
            }
            .mobile-upSideNav{
        
            display: block;
        }
            .addrssAdd .modal-content{
                left: 50% !important;
            }
            .upSideNave{
                display: none;
               
            }
            .userProfile {
            
                flex-direction: column;
            }
            
            .profileLoader{
                flex: none;
                height: 56vh !important;
            }
            .passChange .flexForm{
                flex-wrap: wrap;
            }
            .passChange .inputDiv{
                width: 50%;
            }
            .tableContainer table{
                min-width: 648px;
            }
        
        }
        .upTable-buttonMobile{
            display: none;
        }      
          .upTable-buttonMobile button{
              display: inline-flex;
          }

          @media screen and (max-width: 1199px){
            .walletCard{
                margin: 32px 0;
                margin-top: 64px;
            }
            .bodyCard .order-last ,.bodyCard .cpMiddle-title2{
                margin-top: 0 !important;
            }
            .chrgCenter{
                width: 100%;
            }
          }
        @media screen and (max-width: 767px){
            .tarnsactionTableCard p{
                font-size: 24px !important;
            }
            .tarnsactionTableCard th{
                font-size: 18px !important;
 
            }
           
            .bodyCard{
                box-shadow: none !important;
                padding: 0 !important;
                margin-top: 24px;
            }
            .buttonCard .upTable-title{
                font-size: 24px !important;
            }
            .buttonCard{
                height: 64px !important;
            }
            .addressAdd .formBtns{
                display: flex;
                justify-content: space-between;
            }
            .addressAdd .formBtns button{
                width: 45%;
            }

            .addressAdd  p{
                font-size: 18px;
                margin-bottom: 8px !important;
            }
            .addressAdd label{
                color: var(--neutrals-2, #354052) !important;
margin-bottom: 8px;
                font-size: 16px;

            }
            .map2{
                height: 50vw;
                margin-top: 24px;
            }
            .upAdressCard p{
                max-width: 75%;
            }
            .upAdressCard-btn1{
                width: fit-content;
                padding: 8px;
            }
            .upTable-buttonMobile{
                display: block;
                text-align: end;
                margin: 24px 0;
            }
            .addressContainer{
                padding: 0;
                box-shadow: none;
            }
            .upTable-addressBtn{
                display: none;
            }
            .delegates{
                margin-top: 24px;
            }
          .delegates  .col-sm-6{
              padding: 0;
          }
            .delegates .auth{
                width: 100%;

            }
           .delegates .authTitle{
               width: 100%;

           }
           .delegates span{
               margin: 0 2px !important;
           }
            .delegateContainer{
                padding: 16px;
            }
            .delegates .auth p{
                margin-bottom: 24px !important;
                width: 100%;

            }
            .addDoc .modalBtns{
                text-align: center !important;
            }
            .dataContainer{
                padding: 16px !important;
            }
            .authTitle{
                font-size: 20px !important;
            }
            .addAuth, .upTable-button{
                font-size: 16px ;
  padding: 8.5px;
            }
            .regesterStepsContainer .selectMobile{
                display: block;
                width: 230px;
                margin: auto;
                margin-bottom: 24px;
            }
           .selectMobile select{
                   
  background: #FFFFFF;
  border-radius: 8px;
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.3px;
  width: 100%;
  color: #D16700;
  border: 1px solid var(--gray-300, #D0D5DD) ;
  padding: 4px 10px;
            }
           
 .selectMobile svg{
    color: rgba(102, 112, 133, 1);
}
.regesterStepsContainer{
    max-width: 90vw;
}
        .regTitle{
display: none;        }
        .map{
            height: 50vw!important;
max-height: 300px;
        }
        .dataEdit{
            margin-top: 0 !important;
        }
    }
         @media screen and (max-width: 670px){
            .advPaymentReg .userDataExt{
background-color: transparent;
            }
         .advPaymentReg   .dataEdit{
             margin-top: 16px !important;
         }
            .changePass{
                font-size: 20px;   
                margin: 24px auto;
                text-align: center;
   display: block;
            }
            .addressTitle{
              
                margin: 24px 0;
                font-size: 20px;
                text-align: center;
            }
            .passChange .inputDiv{
                width:100%;
                margin-bottom: 16px !important;
            }
      
            .passChange .inputDiv div{
                margin: 0 !important;
            }
         .passChange   .formBtns{
             text-align: center;
         }
         .userDataExt .formBtns{
             margin: 24px 0 !important;
             text-align: center;
         }
          .passChange label{
              margin-top: 0;
          }
             .contRegBtn{
                 margin-bottom: 8px;
             }
            .contReg  .contd {
                padding: 7.5px 16px;
                margin: 0;
                width: 100%;

            }
            .contd svg{
                width: 15px;
                height: 15px;
            }
             .contReg{
                 justify-content: space-between;
             }
            .cancel, .confirm,.cancelEdit{

                    width: 40%;
            }
            .successModal{
                padding: 16px 8px  !important;
            }
          .successModal  .logoutImg{
              margin-bottom: 24px;
          }
            .successModal button{
                width: 45%;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                padding: 3px;

            }
            .contReg .select-container ,.contReg .contd{
                display: block;
            }
            .contReg{
                margin: 24px 0;
                padding: 0;
            }
            .contReg button{
                font-size: 14px !important;
            }
            .contReg select{
                display: block;
                   
  background: #FFFFFF;
  border-radius: 8px;
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  min-width: 176px;
  letter-spacing: -0.3px;
  
  color: #D16700;
  border: 1px solid var(--gray-300, #D0D5DD) ;
  padding: 4px 10px;
 max-width: 50%;
            }
.contReg .select-arrow svg{
    color: rgba(102, 112, 133, 1);
}
            .formTopper{
        height: 64px;    }
            .usrTabs {
                display: none;
            }
            .formTopper p{
                font-size: 24px;
            }
            .disabledForm label{
                color: rgba(99, 50, 53, 0.74);
        font-size: 18px !important;
        font-weight: 400 !important;
        
            }
            .userDataExt{
                padding: 0 !important;
                box-shadow: none !important;
            }
            .disabledForm input, .disabledForm select,.selectedBranches{
                color: var(--gray-500, #667085) !important;
font-family: Cairo;
font-size: 16px !important;
font-style: normal;
font-weight: 400 !important;
            }
           
        }

@media screen and (max-width: 500px) {
    
    .alrt{
        text-align: center !important;
    }
    .regesterStepsContainer{
max-width: 95vw;
    }
    .nextStep2 button{
        margin-top: 26px;
    }
    .regesterStepsContainer .authPadding{
margin-top: 24px !important;
    }
    .authNoPadding{
        padding: 0 !important;
    }
    .nextStep2{
        margin-top: 0;
    }
    .addAuth, .upTable-button{
        font-size: 14px;
    }
    .addAuth svg {
        margin: 0 2px;
        fill: white;
        width: 15px;
        height: 15px;
    }
    .upInfo-name{
        font-size: 16px;
        margin-bottom: 0;
        line-height: normal;
    }
    .upInfo-number{
        font-size: 14px;
    }
    .upInfo{
position: relative;

    }
.upImageBorder{
border-width: 2px;
padding: 0.1em
}
.mobile-upLinks p{
    font-size: 14px;
}
.mobile-upLinks span{
margin: 0 0.5em;
}
.upTable-title{
    font-size: 16px; 
}
.table th{
    font-size: 14px !important; 
}
.table td{
    font-size: 14px !important; 
}
.table button{
    font-size: 14px !important;    
}
.upTable-button{
    font-size: 14px !important; 
    height: fit-content;
}
.profileBody{
    margin-top: 0;
}
.tc-p22{
    font-size: 26px !important;  
    line-height: 34px;
 margin-top: 24px !important;
}
.tc-p3{
    font-size: 12px;
}
.tc-p1{
    font-size: 20px;
}
.tc-img img{
width: 58px;
}
.transactionCredit{
height: unset;
padding: 16px 12px;
}
.walletBottom{
    padding: 16px !important;
}
.chrgWallet button{
    font-size: 14px;   
}
.chargeWallet label{
    font-size: 14px; 
}
.chargeBtn button{
    font-size: 14px;
    line-height: 40px;
}
.dateT{
    font-size: 13px;   
}
.date{
    font-size: 14px !important;   
}
.spaceAround{
height: 160px;
}
.exCredit{
    font-size: 16px !important;
}
.exCurrency{
    font-size: 16px !important;
}
.currencyValue{
    font-size: 16px !important;
}
.exBox{
padding: 0;
}
.pExch button{
    font-size: 14px;
    height: 46px;
}
.rotate{
    height:40px;
    position: relative;
    top: 5px;
}
.userData label{
font-size: 14px;
}
.inputDiv span{
    font-size: 12px !important;   
}
.eyeEn{
top: 5px !important;
}
.eye{
    top: 5px !important; 
}

.dataEdit{
    font-size: 14px;
    width: 30%;
}
.cancelEdit{
    font-size: 14px;
}
.errMsg{
    font-size: 12px; 
}
.currencyValue{
    font-size: 14px; 
}
text{
    font-size: 16px !important;     
}
.spacebtn p{
    font-size: 14px;  
}
.upAddress{
    align-items: center;
}
.chrgCenter img{
    width: 40px;
}

.upAdressCard p{
    font-size: 13px ;    
}
.upAdressCard-p1{
    font-size: 14px !important;    
}
.upAdressCard-btn1{
    font-size: 13px !important; 
}
}

@media screen and (min-width: 1700px){
    .upTable-title{
        font-size: 1.8vw;
        margin-bottom: 1.5em;
    }
.userData label{
    font-size: 1.5vw !important;

}
.cartProcess{
overflow: hidden;
}
.upSideNave{
    max-width:19vw;
}
.userData input{
    font-size: 1.3vw !important;
 
}
.changePass{
    font-size: 1.6vw !important;
 
}
.formBtns button{
    font-size: 1.4vw;
    padding: 0.3em 0;
}
    .table th{
        font-size: 1.3vw !important;
 
    }
    .cancelOrder{
        font-size: 1.8vw !important;
        line-height: 2.5vw !important;
    
    }
    .modalBtns button{
        font-size: 1.4vw !important;

    }
    .modal-content{
    max-width:30vw !important

    }
    .close-modal svg{
width: 2.5vw;
height: 2.5vw;
    }
    .table td{
        font-size: 1.3vw !important;
   
    }
    .table button{
        font-size: 1.3vw !important;
  
    }
    .upName{
        font-size: 1.8vw;
        margin-top: 0.5em;

    }
    .upPhone{
        font-size: 1.6vw;
 
    }
    .upImageBorder .profileImage{
        max-width: unset;
        max-height: unset;
        border-radius: 50%;
    }
    .upImageBorder{
border-width: 0.35vw;
    }
    .editImage svg{

        width: 2.5vw;
        height: 2.5vw;
    }
    .upLinks p{
        font-size: 1.4vw;
 border-width: 8px;
    }
   .upLinksActive{
    border-width: 8px !important;  
   }
   .upAdressCard p{
       font-size: 1.4vw;
   }
   .upAdressCard-btns button{
    font-size: 1.4vw;
  
   }
   .upAdressCard-btns svg{
    width: 1.8vw;
    height: 1.8vw;
  
   }
   .upAdressCard-btns{
       bottom: 0.5em;
   }
   .upTable-button{
    font-size: 1.7vw;

   }
   .upTable-button svg{
    width: 2.2vw;
    height: 2.2vw;
  
   }
   .addressTitle{
    font-size: 1.7vw;

   }
   .addressInput label{
    font-size: 1.4vw;

   }
   .userAddAddress input{
    font-size: 1.3vw !important;

   }
   .userAddAddress input::placeholder{
    font-size: 1.3vw !important;

   }
   .addressInput button{
    font-size: 1.4vw ;
    padding: 0.5em 0;

   }
   .tc-p1{
    font-size: 1.4vw ;
 
   }
   .tc-p3{
    font-size: 1.3vw ;
  
   }
   .tc-p22{
    font-size: 5.5vw ;
  
   }
   .tc-img img{
      
       height: 8vw;
   }
   .chrgWallet button{
    font-size: 1.6vw ;

   }
   .cpMiddle-title2{
    font-size: 1.8vw ;

   }
   .chargeWallet input[type="number"]{
       font-size: 1.3vw !important;
       padding: 0.5em 1em;
   }
   .chrgCenter{
       display: flex;
       align-items: center;
       margin-bottom: 0.5em;
   }
   .chrgCenter img{
       width: 4vw;
   }
   .chrgCenter input{
height: 1.6vw !important;
    width: 1.6vw !important;
   }
   .chargeWallet input[type="number"]::placeholder{
    font-size: 1.3vw;
}
.chargeWallet label{
    font-size: 1.4vw ;

}
.chargeBtn button{
    font-size: 1.7vw ;
padding: 0.3em 0;
}
.exCredit{
    font-size: 1.7vw ;
  
}
.exBox .exCurrency{
    font-size: 1.6vw ;

}
.pExch button{
    font-size: 1.4vw ;
padding: 1em 0;
height: unset;
}
.exMid .rotate{
    width: 6vw;
}
.dateT{
    font-size: 1.3vw ;

}
.cardTop .date{
    font-size: 1.4vw ;

}
.points{
    font-size: 1.4vw ;
   
}
.cardTop img{
    width: 3.4vw !important;
    height: 3.4vw !important;

}
.pointsT{
    font-size: 1.4vw ;

}
.barCode{
    max-width: 30vw;
}
.barCode svg{
    max-width: 30vw;
background-color: white; 
margin-bottom: 4vw; 
box-shadow: 0px 0px 5px rgb(0 0 0 / 25%);
height: 6vw;
}
.barCode text{
    font-size: 1.4vw !important;
}
.barCode .cardTop{
    align-items: center;
}
.spaceAround .decore{
    width: 15vw;
}
.errMsg{
    font-size: 1.5vw !important;
}
}
.prevAr a{
    border-radius: 0 .125rem  .125rem  0 !important;
  }
  .nextAr a{
    border-radius:.125rem 0 0 .125rem !important;

  }
  .pagination a{
    font-family: Cairo;
  }
  .pagination{
    margin-top: 1em;
  }
  .pagination{
   justify-content: end !important;
  }

  .paginate{
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Cairo";
    gap: 5px;
  }
  .paginate .page-num{
   
    cursor: pointer;
    border-radius: 3px;
    font-family: "Cairo";

  }
  .paginate .page-num:hover{
    background-color: #E47100;
    color: white; 
  }

  .activePage{
    padding: 0px 8px;
    border-radius: 3px;

    background-color: #E47100;
    color: white !important;
  }
  .css-nen11g-MuiStack-root{
 align-items: center;
 margin: 3em 0;
  }
  .css-nhb8h9{
    align-items: center;
    margin: 3em 0;
     }
  .css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected{
    background-color: #E47100 !important;
    color: white !important;
    border: 1px solid #e47100;


  }
  .css-2gftvx {
    border-radius: 0!important;
    margin: 0!important;
}
  .css-2gftvx.Mui-selected{
    background-color: #E47100 !important;
    color: white !important;
    border: 1px solid #e47100;
  }
  .css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root{
    margin: 0 !important;
    border: 1px solid #dee2e6;

    border-radius: 0 !important;
  }
  .css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root:hover{
    background-color: #E47100 !important;
    color: white !important; 
    border: 1px solid #e47100;

  }
  .css-nen11g-MuiStack-root button{
    font-family: 'Cairo';
  }
  .arArrow .css-g2z002-MuiSvgIcon-root-MuiPaginationItem-icon{
    transform: scaleX(-1);
  }
.emptyData{
  height: 95px;
}
.MuiPaginationItem-icon{
padding: 0 0.5em;}

prevAr.MuiPaginationItem-icon::before {
font-family: "cairo";
  content: "السابق ";
  font-size: 14px;

}
prevEn.MuiPaginationItem-icon::before {
  font-family: "cairo";
    content: "Prev";
    font-size: 14px;

  }
  nextAr.MuiPaginationItem-icon::before {
    font-family: "cairo";
      content: "اللاحق";
      font-size: 14px;

    }
    nextEn.MuiPaginationItem-icon::before {
      font-family: "cairo";
        content: "Next";
        font-size: 14px;

      }
      .css-1s2u09g-control{
        overflow: scroll;
        white-space: nowrap;
      }
       .dataTables_info,  .pagination, .bs-select, .mdb-datatable-filter{
          display: none;
      }
      .customerBodyEn{
     
            margin-left: 264px;
            margin-right: 0 !important;
        
      }
      .inputDivDisabled label {
        color: #633235 !important;
        margin: 0;
        white-space: nowrap;
    }
    .inputDivDisabled{
        display: flex;
        align-items: center;
        height: 100%;
    }
    .inputDivDisabled select {
        color: #4F4F4F !important;
        padding: 0;
        border: none;
        appearance: none;
        margin: 0 10px;
        pointer-events: none;
        width: unset !important;
    }
      
.bodyCard{
    box-shadow: 1px 1px 5px 0px rgba(46, 46, 46, 0.20);
    border-radius: 6px;
    padding: 24px;
}
.buttonCard{
    background-color: #FFF7F1;
    text-align: end;
    padding: 30px;
    position: relative;
}
.buttonCard button{
    border-radius: 6px;
    font-family: Cairo;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    width: 130px;
    padding: 10.5px 0;
    border: none;
color: white;
    background-color: #E47100;
}

.contReg button{
    border-radius: 6px;
    font-family: Cairo;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    width: fit-content;
    padding: 9px 16px;
    border: none;
color: white;
    background-color: #E47100;
}
.contReg button:hover{
    background-color: #e9892a;

}
.buttonCard button:hover{
    background-color: #e9892a;

}
.buttonCard .upTable-title{
position: absolute;
bottom: -30px;
z-index: 2;
font-weight: 700;
    color: #E47100;
}
.walletCard{
  display: flex;
  align-items: center;

}
.select-arrow svg{
    height: 16px;
    width: 16px;
}
.transactionCredit{
    margin-top: 2em;
}
.walletBottom{
    background-color: #D16700;
    display: flex;
    border-radius: 0px 0px 12px 12px;
align-items: center;
    justify-content: space-between;
    padding: 50px 30px 30px 54px;
}
.walletBottom p{
    color: white;
    text-align: start;
    font-size: 18px;
font-style: normal;
margin-bottom: 5px;
font-weight: 600;

}
.walletNameTxt{
    font-size: 20px !important;
    font-weight: 700 !important;
margin-bottom: 0 !important;
 
}
.walletLogo {
    width: 69px;
    height: 41px;
    background-size: contain;
    background-repeat: no-repeat;  
}
.tarnsactionTableCard{
    
    border-radius: 12px;
border: 1px solid  #EAECF0;
background: white;
padding: 24px;
text-align: start;
font-family: Cairo;

margin-top: 30px;
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
}
.tarnsactionTableCard p{
    color: #344054;
    font-weight: 700;
border-bottom: 1px solid  #EAECF0;
padding-bottom: 10px;
    font-size: 32px;

}

.tarnsactionTableCard th{
color: #475467;
font-size: 20px;
font-style: normal;
text-align: center;
font-weight: 700;
background: #FFF7F1;
padding: 12px;


}
.tarnsactionTableCard td{
    text-align: center;

    padding: 12px;
    border-radius: 6px;
    color: #000;
font-size: 16px;
font-style: normal;
font-weight: 600;
border-bottom: 1px solid  #EAECF0;

}
.table-header{
    position: sticky; /* make the table header stick to the top */
    top: 0;
}
.tarnsactionTableCard .amount{
    color: #129E4E;
 
}
.tarnsactionTableCard .deduct{
    color: #B73535;
 
}
.tarnsactionTableCard table{
    width: 100%;
  }
  
  .tableContainer{
    height: 450px; /* set the height of the table container */
    overflow: auto; /* make the table body scrollable */
  
  }
tbody{

    border: 1px solid  #EAECF0;
    border-radius: 6px;
    border-top: none ;
  

}


.buttonCard svg{
  margin-left: 5px;
}
.userProfileEn .buttonCard svg{
    margin-left: 0 !important;
    margin-right: 5px;
}

.dataContainer{
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.10);
    padding: 24px;
}
.disabledForm label{
    color:  #633235;
    margin: 0;
    font-size: 20px;
    font-weight: 700;
    white-space: nowrap;
}
.disabledForm input, .disabledForm select{
    color:  #4F4F4F !important;
    padding: 0;
    font-size: 20px;
    font-weight: 400 !important;
    border: none;
    margin: 0 10px;
    width: unset !important;

}
.regStep3Ar label svg {
margin: 0 !important;
}
.disabledForm .inputDiv{
    display: flex;
    align-items: center;
}
.docTitle{
    text-align: start;
    color:  #1D2939;
    font-size: 36px !important;
    margin-bottom: 36px !important;
    font-style: normal;
    font-weight: 600;
}
.dataContainer{
    justify-content: center;
}
.addDoc .modalBtns{
    margin-top: 36px;
    text-align: end;
}
.addDoc button{
    width: fit-content;
}
.usrTabs div{
    overflow: hidden;

}
.successModal .modalBtns{
    text-align: center;
}
@media screen and (max-height: 684px) {
    .upSideNave{
      position: absolute;  
      height: fit-content;
      padding-bottom: 24px;
      height: 100%;
    bottom: 0;
    height: fit-content;
    overflow: hidden;
    }
    
}
.mobile-upLinks{
    padding:0 16px;

}


@media screen and (max-width: 960px) {
    .upLinks{
        padding: 30px 15px;
    }
    .upSideNave{
        width: 230px;
    }
    .profileBody{
        margin-right: 236px
    }
    .userProfileEn .profileBody{
        margin-left: 236px;
        margin-right: 0 !important;
    }
}