@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@300;400;500;600;700;800&display=swap');
.App {
  text-align: center;
}
.fluid{
  --bs-gutter-x: 3rem;

}
@media screen and (max-width: 766px){
  .fluid{
 
    --bs-gutter-x: 15px;
}
}
input[type="radio"] {
  cursor: pointer;
  accent-color: rgb(228, 113, 0);
  width: 20px;
  height: 20px;
}
 input{
   font-family: 'Cairo';
 }
 input::placeholder{
  font-family: 'Cairo';
}

.loader{
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
::-webkit-file-upload-button {
  display: none;
}

input[type=file] {
cursor: pointer;

}
p,a, button{
  font-family: 'Cairo';
}
.regStep3 input[type=file], .regStep3Ar input[type=file]{
  cursor: pointer;
  color: transparent !important;
  }
  .regStep3 input[type="file"]::before {
  content: "File uploaded";
  color: gray;
  position: absolute;
}

.regStep3Ar input[type="file"]::before {
  content: "الملف مرفوع";
  color: gray;
  position: absolute;
}
.regStep3Ar .fileUploaded input{
  color: #633235 !important;

}
.regStep3Ar .fileUploaded input[type="file"]::before{
  content:none
}
.chosenSelect{
  color: #354052 !important;

}

.footerLink{
  font-weight: 600;
  font-family: 'cairo';
  font-size: 14px !important;
  text-align: end;
  padding: 10px 16px;
  background:  #F2F4F7;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  justify-content: space-between;
}

.footerLink p{
  margin-bottom: 0;
}
.footerLink a,.footerLink span {
color:   #E47100 !important;
text-decoration: none;
}

@media screen and (max-width: 700px) {

.footerLink{
  display: block;
  text-align: center;
}
}