
.secondaryNav{
    margin: 0 !important;
    

}
.alignName{
    display: flex;
align-items: center;
}
.alignName div{
    margin: 0 8px;
}
.secondaryNav #globe{
color:  white !important;
}
.alignName p{
    margin-bottom: 0;
    color: #667085;
    font-style: normal;
    font-weight: 700;
}
#globe{
    text-transform: uppercase;
display: flex;
align-items: center;
    color: #e47100;
    font-family: Cairo;
    font-style: normal;
}
#logoutBtn{
position: relative;
bottom: 1.5px;
}
#globe svg{
    margin-left:  8px;
}
.navbarContainerEn #globe svg{
    margin-left:  0 !important;

    margin-right:  8px;

}
.alignName p:hover{
    color: #667085 !important;

}
.navShadow{
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25);

}
.navbarContainer{
    background-color: #FFFFFF;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
z-index: 10;
position: relative;
width: 100%;
}

.navLogo{
    background-repeat: no-repeat;
    height: 35px;
    width: 225px;
    cursor: pointer;
    background-size: contain;
}
.navbarContainer a{
    text-decoration: none;
}
.navbarTop{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;

}
.usrIcn{
    position: relative;
    top: 5px;
}
.navbarTop select{
    background: none;
    border: none;
    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 30px;
color: #4F4F4F;
cursor: pointer;

}
.navbarTop p{
display: flex;
    font-family: 'Cairo'; 
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 30px;
letter-spacing: -0.3px;
margin-bottom: 0;
cursor: pointer;

}
.navbarTop p:hover{

color: #E47100;
}

.navbarOptions{

    display: flex;
    justify-content: space-between;
    align-items:center;

 
} 
a{
    color: unset;
}
.navbarLogo img{

    background: #420F12;
    border-radius: 0px 0px 20px 20px;
    width: 100%;
    padding: 0.4em 1em;
}
.navbarBottom{
    background: #420F12;
    border-radius: 12px;
    margin-top: 2em;
    padding: 0.8em 3em;
    width: 100%;
    display: flex;
    justify-content: space-between;
    text-align: center;
}
.navbarBottom .active p{
   

        color: #E47100;
      
        
  
   

}


.navbarBottom .active path{

    color: #E47100;
    stroke: #E47100;
    
    }

    .nav2Mobile  path{

        color: #4F4F4F;
        stroke: #4F4F4F;
        
        }


.navbarBottom p{

margin-bottom: 0;
cursor: pointer;


font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 15px;
line-height: 30px;
letter-spacing: -0.3px;

color: white;
}
.hospitable{
position: relative;
bottom: 2px;
}
.navbarBottom p:hover{

    color: #E47100;
  
    }
    .navbarBottom p:hover path{

        color: #E47100;
        stroke: #E47100;
        
        }
   


    .navIcon{
        margin:0 0.5em; 
    }
    .navBorder{
   
        border-left: 1px solid white;
     
    }
    .navBorder1{
        border-left:1px solid #D9D9D9;
        line-height: 20px !important;
        margin: 0 1em;
    }
    .count1{
        position: relative;
    }
    .count1::before{

        content: attr(data-count);
        position: absolute;
        font-weight: 400;
font-size: 12px;
        top:-0.4em;
        left: 1em;
        right: 1.2em;
        
        width:1.2em;
        height: 1.2em;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: #e47100;
        color: white;
         }
         .count2::before{
            top:-0.7em;
         }
         .navbarOptions .orderNow{
                      font-family: 'Cairo';
         font-style: normal;
         font-weight: 700;
         font-size: 14px;

  
         letter-spacing: -0.3px;
         
         color: #FFFFFF;

         border-radius: 5px;
         background: #E47100;
    border: none;
      
         padding: 13.5px 1em;
         }
         .navbarOptions button{
margin-right: 16px;
         }
      .navbarContainerEn .navbarOptions button{
            margin-left: 16px;
            margin-right: 0 !important;
                     }
#logoutBtn, #globe{
    background-color: transparent;
    border: none;
    margin-top: 0 !important;
}

.usrProfile{
    background-size: cover;
    width: 40px;
    cursor: pointer;
    border-radius: 100%;
height: 40px;
}
         .navbarOptions .orderNow:hover{

            background: #ec7e11;

         }
         .togg{
             padding: 0;
         }

         .nav2Mobile{
padding: 0 1em;

right: -3em;
         }
         .nav2Mobile select{
             display: block;
             margin-bottom: 0.5em;
             font-family: 'Cairo';
             font-style: normal;
             font-weight: 700;
             font-size: 13px;
         }

         .nav2Mobile p{
        
            cursor: pointer;
            margin-bottom: 0.5em;
            
            font-family: 'Cairo';
            font-style: normal;
            font-weight: 700;
            font-size: 13px;
            line-height: 30px;
            letter-spacing: -0.3px;
            
            color: #4F4F4F;
       
           
                     }
                     .count1{
                         margin: 0 0.3em;
                     }
     
                     .nav2Mobile p:hover{

                        color: #E47100!important;
                        background-color: #e4720013;
                     }

                     .active p{
                        color: #E47100!important;
                      
                    }

                    .nav2Mobile p:hover path{ 
stroke: #E47100;
                        color: #E47100!important;
                     }

                     .active path{
                         stroke: #E47100;
                        color: #E47100!important;
                      
                    }
              
                    #navbarMobile{
                        display: none;
                        position: relative;
                        z-index: 10;
                    }
                    .sideNav{
                    
                        background: rgba(52, 64, 84, 0.2);
                        backdrop-filter: blur(4.5px);    height: 100vh;
                        width: 100vh;
                        top: 0;
                        left: 0;
                        transition: 1s;
                    opacity: 1;
                        z-index: 10;
                        position: fixed;
                    }
                    
                    .navClosed{
                    pointer-events: none;
                        opacity: 0;
z-index: -1;                        transition: 1s;
                        
                        }
                        .mobileLinksClose {
                            -webkit-transform: translateX(-300px) !important;
                            transform: translateX(-300px) !important;
                            transition: 1s;
                            z-index: -1 !important;
                        }
                        .mobileLinks {
                            background-color: #fff;
                            height: 100vh;
                            left: 0;
                            padding: 10px 15px 15px;
                            position: absolute;
                            position: fixed;
                            text-align: end;
                            top: 0;
                            -webkit-transform: translateX(0);
                            transform: translateX(0);
                            transition: 1s;
                            width: 300px;
                            z-index: 11;
                            padding-top: 26px;
                        }
                        .closeNav path {
                            stroke: #fff;
                        }
                        .closeNavHidden {
                            opacity: 0 !important;
                            pointer-events: none !important;
                            transition: 0s !important;
                        }
                        
                        .closeNav {
                            display: inline-block;
                            opacity: 1;
                            pointer-events: all;
                            position: fixed;
                            right: 0.5em;
                            top: 15px;
                            transition: 2s;
                            z-index: 12;
                        }
                        .navbarEn  .mobileLinksClose{
                            transform: translateX(300px) !important;
                          }
                          .navbarEn .mobileLinks{
                            left: auto !important;
                            right: 0;
                          }
                          #navList {
                            border-bottom: 1px solid #d0d5dd;
                            list-style: none;
                            margin-top: 1em;
                            padding: 0;
                            text-align: start;
                        }
                        #navList li {
                            margin-bottom: 15px;
                        }
                        #navList .active {
                            background: #f9fafb;
                            border-radius: 6px;
                            color: #ed9434;
                        }
                        
                        #navList a,  #navList  p {
                            align-items: center;
                            color: #344054;
                            display: flex;
                            font-size: 16px;
                            cursor: pointer;
                            font-weight: 600;
                            line-height: 24px;
                            padding: 8px 10px;
                            text-decoration: none;
                            width: 100%;
                            font-family: cairo;
                        }
                        #navList .active svg {
                            color: #ed9434;
                        }
                        #navList svg {
                            color: #667085;
                            margin-left: 5px;
                        }
                        #navList li:hover{
                            background: #F9FAFB;
                            border-radius: 6px;
                            
                            
                        }
                        #navList li:hover a, #navList li:hover svg,   #navList li:hover p{
                            color: #ED9434;
                        }
                        #navFlex{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                        }
                        .navFlex{
                            display: flex !important;
                            align-items: center;
                        }
                        .navFlex a{
                            color: #344054;
                            display: flex;
                            font-size: 16px;
                            cursor: pointer;
                            font-weight: 600;
                            line-height: 24px;
                            text-decoration: none;
                            margin: 0 12px;
                            font-family: cairo;
                        }
                        #navFlex p{
                            margin: 0 12px;
                        }
                        #navFlex div, #navFlex p{
                            display: inline-block;
                        }
                        .toggleBtn{
                            
                        }
                        .loginNav button{
color: white  !important;
                        }
         @media screen and (max-width: 1050px){
           
        .navbarBottom span{
            display: none;
        }
        .remove{
            display: none;
        }
        }


@media screen and (max-width: 900px){
    .navbarBottom {
        display: none;
    }
    .links{
        display: none;
       
    }
    .togg{
        display: inline !important;
    }
   
 .navbarOptions select{
   
 }
.mobileNavRemove{
    display: none !important;
}
}

@media screen and (max-width: 600px){
    .navbarTop {
    }

.navbarLogo img {

    max-width: 200px;

}

}

.secondaryNav .navbarContainer{
    background-color: transparent;
    }
    .secondaryNav .orderNow{
        color: #364D6C ;
    background-color: white;
    }
    .secondaryNav svg{
        color: white !important;
    }
    @media screen and (max-width: 991px){
        .navbarContainer {
margin: auto !important;       
max-width: 90vw !important; }
    
    
    
    }
    @media screen and (max-width: 815px){
        .navLogo {
            height: 38px;
            width: 260px;}
    
    
    
    }
    @media screen and (max-width: 767px){
        .navShadow{
            box-shadow: none;
        }
        .navLogo {
            height: 50px !important;
            max-width: 50vw;
            width: 260px !important;}
    
      #navbarMobile{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        padding-top: 20px;
    }
    #navbarMobile button{
        background-color: transparent;
        border: none;
        margin-top: 0 !important;
    }
    #webNavbar{
        display: none;
    }
    .mobileLinks .navLogo{
        margin-right: auto ;
    }
    .navbarEn .navLogo{
        margin-right: 0 !important ;
        margin-left: auto ;

    }
    .navbarEn .closeNav{
        right: auto !important;
        left: 0.5em;
    }
  .navbarEn  #navList svg {
    margin-left: 0 !important;       
     margin-right: 5px;
    }
    }
    .loginNav svg{
color: white !important;
    }
    .loginNav a button{
        background-color: white !important;
    color: #E47100 !important;
    }
    .navrAr .navLogo{
width: 225px;
height: 68px;
    }
    .navrAr .navbarContainer{
        padding: 4px 0 !important;
    }
    .upSideNaveAr .usrNavLogo div{
        height: 47px;
    }
    .dimmed{
      opacity: 0.6;
      font-size: 14px !important;
      padding: 13.5px 30px !important;
    }