.chicken{
    height: 92vh;
    background-repeat: no-repeat;
background-size: cover;
position: relative;
left: -20px;
z-index: -1;

}
.addrssAddEn .addrssAdd .modal-content{
    left: auto !important;
}
.logout{
    margin-bottom: 0;
    font-family: 'Cairo';
font-style: normal;
font-weight: 300;
text-decoration: underline;
cursor: pointer;
color: white;
}
.ordrNw{
    display: flex;
    height: 92vh;
    align-items: center;
}
.alrt{
    margin-bottom: 1em;

 font-family: 'Cairo';
 font-style: normal;
 font-weight: 600;
 font-size: 18px;
}
.ordrNw button{

 font-family: 'Cairo';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 15px;

  letter-spacing: -0.3px;
  border: 1px solid #E47100;
border-radius: 10px;
  color: #E47100;
  background-color: white;
width: 48vw;
margin: auto;
padding: 1em 0;
justify-content: space-around;
align-items: center;
}
.ordrNw button:hover{

  color: white;
  background-color: #E47100;

}
.logout:hover{
    color: #E47100;

}
.searchCustomer{
    border-radius: 0.25rem!important;
    background: #FFFFFF;
    box-shadow: 0 1rem 3rem rgba(0,0,0,.175);
    text-align: start;
    padding: 2em;
}
.title1{
    font-family: 'Cairo';
font-style: normal;
font-weight: 500;
font-size: 32px;
color:  #331900;
text-align: start;
}
.title2{
    font-family: 'Cairo';
font-style: normal;
font-weight: 500;
font-size: 32px;
color: #331900;
text-align: start;
}
.alignVert{
  position: relative;
  top: 15%;
}
.searchCustomer label{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
margin-bottom: 0.5em;
    color: #828282;
    display: block;
    text-align: start;
}
.searchCustomer button{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
   
    color: #FFFFFF;
    background: #E47100;
border-radius: 7px;
border: none;
padding: 0.5em 1em;
width: 25%;
margin-right: 1em;
}
.searchCustomer button:hover{

    background: #f58617;
}
.searchCustomer input{
border-radius: 6px;
    border: 1px solid #e5e5e5;
padding: 0.5em;
width: 100%;
}
.or{

    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 24px; 
    color: #633235;
    display: inline-block;
  
}
.orHr{
    border-top: 1px solid #e5e5e5;
    display: inline-block;
    width: 80%;
    position: relative;
    top: 1em;
}
.btn1{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
width: 100%;
    
    /* #E47100 */
    
    color: #E47100;
    border: 2px solid #E47100;
border-radius: 8px;
background-color: transparent;
padding: 0.5em;
}
.btn1:hover{
    background-color: #E47100;
    color: white;

}

.btn2{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    width: 100%;
    
    /* #E47100 */
    border: 2px solid #E47100;
    color: white;

border-radius: 8px;
background-color: #E47100;
padding: 0.5em;
} 
.btn2:hover{
    background-color: #f6891c;
    border: 2px solid #f6891c;
 
}
.cstmrSrch{
    position: absolute;
    right: 5px;
    color: #E47100;
    top: 5px;
}
.chickenEn{
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    right: -20px;
    left: auto;
}
.validation{
    color: brown ;
    font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 0.8rem;
text-align: start;
display: block;
}
.noResult img{
margin-bottom: 1em;
}
.noResult p{

    font-family: 'Cairo';
    color: #9d9d9d;
    font-weight: 600;
    font-size: 1rem;
margin-bottom: 0;
}
.editCstmr select{

    border-radius: 6px;
    border: 1px solid #e5e5e5;
padding: 0.5em;
    font-family: "Cairo";
    width: 100%;
    margin-bottom: 1em;
}



@media screen and (max-width: 991px) {
    .chicken{
display: none;
 
    }


}
