.menu{
    margin-top: 2em;
    position: relative;

    text-align: start;
    margin-bottom: 5em;
}
.addressInputErr{
    border: 1.5px solid #d62d2d !important;
}
.errorMsg{
    text-align: start;
    margin-top: 10px;
    font-size: 15px !important;
    color: #d62d2d;
    display: flex;
    align-items: center;
}
.errorMsg span{
    margin: 0 8px;
}
.noAval{
    font-size: 1.25rem;
    font-weight:600;
    line-height: 1.5;
 margin-bottom: 0;
 padding: 2em 0;
    font-family: 'Cairo';
color: brown;
}
#walletMethod{
    display: flex;
    justify-content: space-between;
    width: -webkit-fill-available;
}
.branches .branch div{
    padding: 20px;
    box-shadow: 0 3px 6px rgb(0 0 0 / 10%);
    border-radius: 15px;
    text-align: center;
    background-color: #e9e9e9;
    margin-bottom: 10px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    font-family: 'Cairo';
}
 .branch div:hover{


    background-color: rgb(228, 113, 0);
    color: white;
} 
.branch div{
    padding: 20px;
    box-shadow: 0 3px 6px rgb(0 0 0 / 10%);
    border-radius: 15px;
    text-align: center;
    background-color: #e9e9e9;
    margin-bottom: 10px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    font-family: 'Cairo';
}
.branchSelectActive  div{

    background-color: rgb(228, 113, 0) !important;
    color: white !important;

}
.branchSelectClosed  div{
    cursor: default !important;
    background-color: red !important;
    color: white !important;
}
.branchSelectClosed  div:hover{
    color: white !important;

}
.menuMargin{
    margin-bottom: 89px;
}
.menuTitle {
    color: var(--331900, #331900);
    font-size: 30px;
        font-weight:700;
    line-height: 1.5;
 margin-bottom: 36px;
    font-family: 'Cairo';
}
.menuSrch{
    display: flex;
    justify-content: space-between;
}

.cartItem-quan button:disabled, .cartItem-quan  button[disabled]{

    background-color: transparent !important;
    border: none;
    cursor: not-allowed !important;
}
.menuItemBottom{
    background-color: #F9FAFB;
    border-radius: 0px 0px 10px  10px;
padding: 15px;
}
.meals .mealName{
    text-align: start;
    font-weight: 700 !important;

}
.delPromo{
    background-color: transparent !important;
    color: brown !important;
    font-size: 25px !important;
    padding: 0 !important;
    position: absolute;
    left: 50px;
    bottom: 4px;
}
.delPromo:hover{
    color:#212529 !important;
}
.delPromoEn{
    left: auto !important;

    right: 50px;
}
.meals  .mealDiv{
    border-radius: 9.382px;
border: 1.173px solid var(--gray-200, #E4E7EC);
    border-radius: 7px;
    padding: 0 !important
}
.menuSrch input{
    border: 1px solid #d0d0d0;
    outline: 0;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;

}
.noteText{
   font-family: cairo;
    margin-top: 18px;
    padding: 12px;
    height: 100px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) ;
    border-radius: 8px;
border: 1px solid var(--gray-300, #D0D5DD);
background: var(--base-white, #FFF);

}
.noProd p{
    color: #495057;
    font-family: 'Cairo';


}
.noProd{

    text-align: center;
    position: relative;
    top: 20%;
}
.srchIcn{
    color: #d0d0d0;
position: absolute;
left: 5px;
top: 8px;
}
.srchIcnEn{
    right:  5px; 
    left: auto !important;
}
.cstmrAddress{
    text-align:start;
    
    margin-top: 1em;
}
.cstmrAddress p{
    font-family: 'Cairo';
}
.categories{

  

}

.categoriesEn{
 
}
.customPadding{
    padding: 0 3vw;
}
.categories div{
    color: #667085;
   /* background-color: #633235;*/
    border-radius: 5px;
   /* color: #fff;*/
    font-weight: 600;
    font-family: 'Cairo';
 display: flex;
 align-items: center;
 margin-bottom: 1em;
padding: 0.8em;
cursor: pointer;
font-size: 14px;
border-radius: 6px;
border: 1px solid transparent;
}
.non-touch:hover{
    color:  #1D2939  !important;
    border-radius: 6px;
border: 1px solid var(--gray-200, #E4E7EC);

}
.activeCategory{
    color: #FF7E00 !important;
    border-radius: 6px;
    background: var(--gray-50, #F9FAFB);
    border: 1px solid transparent;

}
.itemBottom{
    display: flex;
    margin-top: 18px;

    justify-content: space-between;
    align-items: center;
}
.mealDiv .p-3{
    border-radius: 9.382px;
    border: 1.173px solid var(--gray-200, #E4E7EC);

}
.categories img{
    width: 40px;
    height: 40px;
    border-radius: 100%;
    float: right;
    
}
.categories p{
    margin-bottom: 0;
}
.meals .info{
cursor: default;
        color: rgb(228, 113, 0) 
}
.tableDisc input{
    border: 1px solid #bebebe;
    border-radius: 5px;
width: 100%;
padding: 0.5em;
}
.meals .mealDiv{
    text-align: center;
 
}
.meals .customPadding{
    max-width: 580px;

}
.meals .mealName{
    cursor: pointer;
    color: #331900;
    font-size: 1rem;
    font-weight: 500;
    font-family: 'Cairo';
    margin-bottom: 0;
  
}
.cartBtn{
    color: #f58617;  
    cursor: pointer;
    display: none;
}

.meals .mealDiv .itemImg{
cursor: pointer;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    width:100%;
    border-radius: 10px 10px 0 0;
    height: 180px;
    margin:auto;

}
.mealDescribe{
    text-align: start;
}
.meals .mealPrice{
    color: #633235;
    font-size: 1rem;
    font-weight: bolder;
    font-family: 'Cairo';
    margin-bottom: 0;
  
}

.meals button { font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    
    color: #FFFFFF;
    background: #E47100;
    border-radius: 7px;
    border: none;
    
    padding: 0.5em 1em;
  

    }
    .meals button:hover{
        color: #633235;
    background: #f58617;
    }
    .modalOverlay {
        width: 100vw;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 11;
    }
    
    .modalOverlay {
        background: rgba(49,49,49,0.8);
    }
    .mealDetail{
        font-weight: 600;
    font-family: 'Cairo';
    color: #331900;
    font-size: 1.5rem;
    margin-bottom: 0.5em;
    } 
    .meal-name{

        color: #000;
text-align:start;
font-size: 32px;
margin-bottom: 24px;
font-style: normal;
font-weight: 700;
    text-align: start;
    
    }
    .meal-detail{
        text-align: start;
        color: #000;
        margin-bottom: 24px;

        font-size: 20px;
        font-style: normal;
        font-weight: 400;
    }
    .meal-size{
        text-align: start;
        font-size: 18px;
        color: #000;
        font-weight: 700;
        line-height: 1.5;
        font-family: 'Cairo';

    }
    .modal-content label{
        font-family: 'Cairo';
        font-size: 17px;
        font-weight: 600;
        line-height: 1.5;
        color: #212529;
    }
    .modal-content input{
height: 20px;
width: 20px;

    }
    .flexOption{
        display: flex;
        align-items: center;
        justify-content: start;
    }
    .meal-img{
        width: 100%;
        height: 20vw;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 28px;
        background-color: #E8E8E8;
                background-position: 50%;
        border-radius: 25px;
margin-bottom: 1em;
    }
    .cal{
        font-size: 20px;
      margin-bottom: 0.2em;
        color: #633235;
        font-family: 'Cairo';
        font-weight: 600;
        text-align: start;
        display: inline-block;

    }
    .calTotal{
        font-size: 16px;
        display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    margin: 0 5px;
    text-align: center;
    color: #828282;
    font-family: 'Cairo';
    }
.addMeal{
    color: #fff;
    padding: 12px;
    font-family: 'Cairo';
  font-weight: 400;
    background-color: #e47100;
    border-radius: 5px;
    border: none;
    font-size: 18px;
    width: 182px;
}
.addMeal:hover{
    color: #633235;
}
.total{
    color: #e47100;
    font-weight: 700;
    font-size: 28px;
    font-family: 'Cairo';
    margin-bottom: 0;
}
.flexPrice{
    display: flex;
    justify-content: space-between;
    font-family: "Cairo";
    font-size: 14px;
  align-items: center;
}
.flexPrice p{
    margin-bottom: 0;
    color: #E47100;

font-size: 16px;
font-style: normal;
font-weight: 700;
}
.quanBtns p{
    color: #475467;
text-align: right;
font-family: Cairo;
font-size: 24px;
font-style: normal;
font-weight: 700;
margin-bottom: 0;
margin: 0 16px;
display: flex;
align-items: center;
}
.quanBtns{
    display: flex;
    align-items: stretch;
}
.quanBtns button{
    border-radius: 8px;
    display: inline-block;

border: none;
    color: #e47100 !important;
}

.mealBottom{
    display: flex;
    align-items: center;
    margin-top: 24px !important;
    justify-content: space-between;
   
}
.removeItem svg{
margin: 0 5px;
}
.removeItem{
    margin-left:3vw;
    padding: 0;
    display: flex;
    align-items: center;
    border: none;
    background: transparent;
    color:#e11857;
}
.menuEn .removeItem{
    margin-right:3vw;
    margin-left:0 !important;
 
}
.detailsBorder{
    border-radius: 8px;
border: 1px solid #D0D5DD;
padding: 24px;
padding-left: 34px;
max-height: 72vh;
overflow: auto;
background: #FFF;
box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);}
.mealBottom button{
    white-space: nowrap;
}
.mealBottom p{
    margin-bottom: 0;
    font-size: 22px !important;
    font-style: normal;
    white-space: nowrap;
    font-weight: 700 !important;
}
.quanBtns button{
background-color: transparent;
display: flex;
align-items: center;
font-size: 35px;
color: #D0D5DD;
line-height: 45px;
padding:0 0.5em ;
padding-bottom: 5px;
}
.quantity-btn1{
    padding-bottom: 4px !important;
  
}
.quanBtns{
    border: 1px solid #bebebe;
    border-radius: 5px;

}
.quanBtns input{
    text-align: center;
    width: 75px;
    border-radius: 5px;
    border: 1px solid #bebebe;
  
height: 30px !important;
}
.cart{

    padding: 18px ;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
        height:fit-content;
   /* overflow-y: auto;
    overflow-x: hidden;*/
}
.err{
    font-family: 'Cairo';
    color: brown;
    font-size: 13px;
}
.cartTop{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;


}
.cartTop p{
    font-family: 'Cairo';
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 0;
}
.cartTop button{
    text-align: center;
    padding: 5px ;
    border: 1px solid #e11857;
    border-radius: 5px;
    cursor: pointer;
    font-family: cairo;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #e11857;
    background-color: transparent;
}
.alrt{
    font-family: Cairo;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -.3px;
    color: #331900;
    font-size: 20px;
    line-height: 32px;
}
.del{
    background: #e47100;
    border: none;
    border-radius: 10px;
    color: #fff;
    font-size: 18px;
    line-height: 34px;
    margin: 0 0.5em;
    padding: 0.3em 2em;
    font-family: Cairo;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -.3px;
}
.del:hover{
    background: #ea7f14;
 
}
.cls{
    background: #fff;
    border: 1px solid #e47100;
    border-radius: 10px;
    color: #e47100;
    font-family: Cairo;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -.3px;
    line-height: 34px;
    margin: 0 0.5em;
    padding: 0.3em 2em;
  
}
.noResult{
    font-family: Cairo;
    font-size: 18px; 
}
.cls:hover{
    color: white;
    background: #e47100;

}
.cartItem-Img{
   
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    height: 121px;
    width: 129px;
    max-width: 100%;
}
.cartItem-quan button{
    width: 24px;
    height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.cartItem-name{
    color: #331900;
    display: inline-block;
    font-weight: 700;
    line-height: 1.2;
    font-size: 18px;
    font-family: "Cairo";
}
.cartItem-delete{
    background-color: transparent;
    border: none;
}
.cartItem{
    margin-top: 1em;
}
.cartItem-detail{

    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 400;
    line-height: 1.2;
    font-family: "Cairo";
}
.foundUrl p{
    color: white;
    font-size: 5vw !important;
    }
.cartItem-quan input{
    background-color: #fff;
   text-align: center;
    width: 66px;
    opacity: 1 !important;
    border-radius: 5px;
    border: 1px solid #bebebe;
  height: 31px;
}
li{
    margin-bottom: 0.5em;
}
.cartItem-quan button{
border: none;
background-color: transparent;

}
.cartItem-price{
    margin-top: 0.5em;
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 400;
    line-height: 1.2;
    font-family: "Cairo";
    
} 
.cart select{
    width: 100%;
    font-family: "Cairo";
    border-radius: 9px;
    border: none;
    background-color: #f5f2f2;
    padding: 5px;
 text-align: right;
    font-weight: 300;
    color: #633235;
    font-family: "Cairo";
}





.dropdown-check-list {
    display: inline-block;
  }
  
.srchNote{
    border: 1px solid #E0E0E0;
    border-radius: 10px;
    font-family: "Cairo"; 
    width: 100%;
    display: flex;
justify-content: space-between;
    align-items: center;
    position:relative;
}
#list1 .srchIcn{

left: 0.2em;
}
.srchNote .srchIcnEn{
    right: 0.2em;
    left: auto !important;
}
 #list1 input[type="text"]{
    width: 100%;
    border: 1px solid #d0d0d0;
    outline: 0;
    padding: 0.2em 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
}
.breadCrumb{
    text-align: start;
    font-family: 'Cairo';
    font-size: 16px;
    color: #e47100 !important;
}
.breadCrumb a{
    text-decoration: none;
  color: #212529 !important;
}
.breadCrumb a:hover{
    color: #ec8926 !important;

}
  .dropdown-check-list .anchor {
    position: relative;
    cursor: pointer;
    display: inline-block;
    width: 100%;
    padding: 6px 15px;
    color: #667085;
  
  }
  .dropdown-check-list.visible .srchNote{

border-bottom: none;
border-radius: 10px 10px 0 0;
  }
  .toggleCart .dropdown-check-list .anchor:after {
      display: none;
  }
  .dropdown-check-list .anchor:after {
    position: absolute;
    content: "";
    border-left: 1.5px solid black;
    border-top: 1.5px solid black;
    padding: 5px;
    left: 0;
    top: 26%;
    -moz-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }
  
  /*.dropdown-check-list .anchor:active:after {
    right: 8px;
    top: 21%;
   
  }
 */
  
  .srchPos{
      position: relative;
      text-align: end;
      margin-bottom: 44px;
      
  }
  .srchPos input{
      width: 80%;
      padding:9.5px 10px;
   

    color: var(--gray-400, #98A2B3);
    border-radius: 6px;
    border: 1px solid  #98A2B3 !important;
    background: var(--gray-25, #FCFCFD);
    font-size: 18px;
  }
  .dropdown-check-list ul.items {
    padding: 2px;
    display: none;
    margin: 0;
  
    border: 1px solid #E0E0E0;
    border-radius: 0 0 10px 10px;
    border-top: none;
    padding: 0 1em;
  }
  .cartTitle{
    color:  #1D2939;
    text-align: start;
    font-family: Cairo;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    margin-top: 29px;
  }
  #list1{
    
      width: 100%;
      font-family: "Cairo";
      border-radius: 9px;
      border: none;
      background-color: white;
      padding: 0 ;

      font-weight: 400;

      font-family: "Cairo";
  }
  
  .dropdown-check-list ul.items li {
      display: flex;
      font-family: 'Cairo';
      align-items: center;
    justify-content: start;
    list-style: none;
  margin: 0.5em 0;
  }
  .dropdown-check-list ul.items span {
  margin: 0 0.5em;
  display: inline-block;
  color: #331900 !important;
  }
  .dropdown-check-list input[type="checkbox"]{
  width: 20px !important;
  }
  .dropdown-check-list.visible .anchor {
    border-radius: 10px 10px 0 0;
    border-bottom: none;
  }
  
  .dropdown-check-list.visible .items {
    display: block;
    overflow-y: auto;
    max-height: 9em;

  }
 
.cartDivTitle{
    font-size: 1rem; 
    font-weight: 600;
    line-height: 1.2;
    font-family: "Cairo";
    text-align: start;
    margin-top: 36px;
}
.cartBreakdown{
    background-color: #F9FAFB !important;
}
.cstmrDetails{

    color: #432102;
    font-size: 14px;
    font-family: "Cairo";
}
.cstmrDetails span{
    color: #292929;
    font-size: 16px;
}
.meals{
  margin-left: auto !important;
  margin-right: auto !important;

}
.menuEn .dropdown-check-list .anchor:after{
left: auto !important;
right: 0;
}
textarea{
    width: 100%;
    border: 1px solid #bebebe;
    border-radius: 5px;
}
.paymentType label{
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    font-family: "Cairo";
}
.paymentType{
  display: flex;
  align-items: center;
  margin-top: 0.5em;
}
.promoCode{
    display: flex;
    justify-content: space-between;
}
.promoCode input{
    border: 1px solid #bebebe;
    border-radius: 5px;
width: 80%;
height: 100%;
} 
.promoCode div{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.quantityLoader{
    position: absolute;
    bottom: 6px;
  width: 100%;
  border: 1px solid #bebebe;
  border-style: none solid none solid;
  background-color: white;
}
.promoCode .tick{
    color: white;
    background-color: #2dbb2d;
    border-radius: 100%;

}
.promoCode button{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    
    color: #FFFFFF;
    background: #E47100;
    border-radius: 7px;
    border: none;

    padding: 0.7em 1em;

}
.promoCode button:hover{

    color: #633235;
    background: #f58617;
}
.cartBreakdown p{
    font-size: 16px;
    font-style: normal;
    color: #828282;
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    margin-bottom: 0;
}
.bdData{
    font-size: 18px !important;
    font-style: normal;
    color: #462200 !important;
    font-weight: 400 !important;
}
.cartBreakdownTotal{
    border-top: 1px solid #bebebe5c;
    padding:  26px 16px !important;
}
.cartBreakdownTotal p{
   color: #331900 !important;
   font-weight: 400 !important;
font-size: 28px !important;
}
.cartBreakdownTotal .bdData {
    font-size: 16px !important;
    font-style: normal;
    color: #828282 !important; 
}
.bdData span{
    font-size: 28px !important;
    color: #331900 !important;

   font-weight: 700 !important;

}
.cartBreakdown {
    padding: 16px;
    padding-top: 0;
    display: flex;
    justify-content: space-between;
    background-color: grey;
align-items: center;
}
.sendOrder{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    
    color: #FFFFFF;
    background: #E47100;
    border-radius: 7px;
    border: none;
width: 100%;
    padding: 15px 1em;
    margin: 26px 0;
    margin-bottom: 0;
}
.sendOrder:hover{

    color: #633235;
    background: #f58617;
}
.meals button:disabled,
button[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  cursor: default;
}
input[type="checkbox"]{

cursor: pointer;
 accent-color: #E47100;
 width: 20px;
 height: 20px;
}
.orderSuccess{
    height: 50vh;
}
.alignCenter{
    position: relative;
    top: 50%;
}
.orderSuccess p{

    font-family: cairo;
    font-weight: 600;
    font-size: 1rem;
 
}
.orderSuccess .success-btn2{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    min-width: 290px;
    color: #FFFFFF;
    background: #E47100;
    border-radius: 7px;
    border: none;
 width: 20%;
    padding: 0.7em 1em;
    margin-top: 0.5em;
    }
    .success-btn2:hover{
        color: #633235;
    background: #f58617;
    }
.menuEn{
    overflow-x: hidden;
}
.orderSuccess span{
    margin: 0 1em;
}
.orderSuccess .success-btn1{
    width: 20%;
    background-color: transparent;
    color: #E47100;
    font-family: 'cairo';
    border: 1px solid #e47100;
        color: #e47100;
        font-size: 15px;
        border-radius: 10px;
        padding: 0 1em;
        height: 45px;
        min-width: 290px;
    }
    .success-btn1:hover{
        background-color: #E47100;
    color: white;
    }
    .mobileDiv{
        display: none;
    }
  
    .cartActn{
        display: none;
        position: fixed;
        left: 0;
        cursor: pointer;
        font-size: 22px;
font-style: normal;
font-weight: 700;
        color: #A25000;
border-radius: 8px;
padding:12px 5px;
background: var(--primary-50, #FFDCB9);
box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.10);      
        border-radius: 0 8px 8px 0;
        box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;
    }
    .cartActn svg{
        color: #A25000;
    }
    .carEn .cartActn{
        left: auto !important;
        right: -5px;
      
      
        border-radius:  8px 0 0 8px ;
    }
    .hiddenOf{
        overflow: hidden;
    }
    .carArrow{
        position: relative;
        top: -7px;
        right: 10px;
    }
    .carEn .carArrow{
        transform: scaleX(-1);
   right: -13px;
        
    }
    .carEn  .carIcn{
        transform: rotate(270deg) scaleY(-1) scaleX(-1) ;
position: relative;
bottom: -8px;
    }
    .carIcn{
        color: white;
        transform: rotate(270deg) scaleY(-1) scaleX(-1) ;
        position: relative;
bottom: -8px;
    }
    .crtTxt{
        font-family: 'Cairo';
        font-size: 14px;
        transform: rotate(270deg) scaleY(-1) scaleX(-1);
        position: relative;
        top: 16px;
    }
    .srchMobile{
        display: none;
    }
    .srchSmall{
        display: none;
 
    }
    @media screen and (max-width: 1296px) {
        .cart{
          min-width: 396px;
        }
      }

    @media screen and (max-width: 1188px) {
        .cart{    
            min-width: 355px;
        }
        .removeItem{
            margin-left:1vw;
           
        }
        .menuEn .removeItem{
            margin-right:1vw;
            margin-left:0 !important;
         
        }
    }
 
    @media screen and (max-width: 991px) {
        .srchPos, .menuSrch{
            display: none;
        }
        
        .srchMobile{
            display: block !important;
            padding: 0 3vw;
        }
        .srchIcn{
            left: 28px;
        }
        .srchIcnEn{
            right: 28px;

        }
        .cartBtn{
            display: inline-block;
 padding: 0;
          border: none;
          background-color: transparent;
    
        }
        .menuContainer{
padding: 0 !important;
max-width: unset;
        }
        .cartActn{
            display: flex;
            
            flex-direction: column-reverse;
        }
        .cartClosed{
            left: -400px !important;
        }
        .cart{
            position: absolute ;
         max-width: 90vw;
           width: 330px;
            z-index: 5;
        
            top: 0;
            transition: 1s ease-in-out;
        }
         .cartOpen{
      
            left: -25px;
        }
        .carEn .cartOpen {
            left: auto !important;
            right: -22px;
        }
        .carEn .cartClosed{
            left: auto !important;
            right: -400px ;
        }
    }
    @media screen and (max-width: 767px) {
        .addModal .modal-content{
position: absolute !important;
top: 80% !important;
        }
        .carEn .cartOpen {
            left: auto !important;
            right: -17px;
        }
        .orderDetTop{
            bottom: 0 !important;
        }
        .srchIcn{
            left: 7px;
            top: 6px;
        }
        .srchPos{
            width: 30vw;
            margin-bottom: 0;
        }
        .menuTitle{
            margin-bottom: 0;
            font-size: 24px;
        }
        .srchPos input{
            width: 100%;
            padding: 5.5px 10px;
        }
        .srchIcnEn{
            right: 7px;

        }
        .srchMobile{
            display: none !important;
        }
        .categAlign{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .srchSmall{
           display: block; 
        }
        .cartOpen{
      
            left: -19px;
        }
        .categories{
            display: flex;
            border: none;
            overflow: scroll;
            padding-bottom: 1em;
        }
      
        .categories div{
            margin-bottom: 0;
margin-left: 0.5em;
        }
        .categoriesEn div{
            margin-right: 0.5em;
            margin-left: 0 !important;
        }
    }
    @media screen and (max-width: 667px) {
      
        .srchPos{
            position: relative;
            width: fit-content;
            margin-bottom: 0.5em;
        }
       
       
    }
    @media screen and (max-width: 575px) {
        .mealBottom button{ font-size: 14px;
        }
        .mealBottom svg{
            margin: 0 5px !important;
            height: 20px;
            width: 20px;

        }
        .carEn .cartOpen {
            left: auto !important;
            right: -13px;
        }
        .cartOpen{
      
            left: -13px;
        }
     .colMobile{
         max-width: 345px;
         margin-left: auto;
         margin-right: auto;

     }
        .meals{
            padding-right: calc(var(--bs-gutter-x) * .5) !important;
            padding-left: calc(var(--bs-gutter-x) * .5) !important;
        }
    .quanBtns{
        text-align: start;
    }
    .total{
        text-align: end !important;
    }
    .mealDetail{
        font-size: 18px;
    }
    .meal-name{
        font-size: 16px;
    }
    .meal-detail, .meal-size, .modal-content label, .cal,.quanBtns p,.total {
        font-size: 14px;
    }
    .calTotal{
        font-size: 13px;
    }
    input[type="checkbox"]{
        width: 15px !important;
        height: 15px !important;
    }
    .addModal .modal-content{
        padding: 12px !important;
    }
    
    .meal-size{
        margin: 5px !important;
    }
    .mealBottom{
        margin-bottom: 24px;
    }
    .meal-name{
        margin-bottom: 8px;

    }
    .meal-img{
        height: 40vw ;
    }
    .additionalWeb{
        display: none;
    }
    .mobileDiv{
        display: block;
    }
    .menu{
        margin-top: 1em;
    }
    }
    @media screen and (max-width: 575px) {
        .mealBottom{
           
        }
    }
    @media screen and (max-width: 550px) {
        .menuTitle{
            font-size: 16px;
        }
        .categories p{
            font-size: 14px;  
        }
        .categories img {
            width: 30px;
            height: 30px;}
            .meals .mealName,.meals .mealPrice{
                font-size: 14px;  
            }
    }