.cpTop{
    padding: 5em;
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center;
    padding-bottom: 0;
}


.activeStep {
cursor: pointer;
 background: #FFF7F0 !important;


}
.activeStep div:before {
  border-left: 40px solid #fff7f0!important;
}

.step1Ar:before {
  border-bottom: 40px solid transparent;
  border-left: 40px solid hsla(0,0%,67%,.1);
  border-top: 40px solid transparent;
  bottom: 0;
  content: "";
  height: 0;
  position: absolute;
  right: -40px;
  width: 0;}

.activeStep div {
  background: #fff7f0!important;
  color: #e47100!important;

  border-radius: 4px;
  height: 80px;
  position: relative;
  width: 200.5px;
}
.datePickerAr .react-datepicker-popper[data-placement^=top]{
  inset: auto auto 0 auto !important;
  z-index: 11;

}
.react-datepicker-popper[data-placement^=top] {
  z-index: 11;

}
.react-datepicker-popper[data-placement^=bottom] {
  z-index: 11;

}
.datePickerAr .react-datepicker-popper[data-placement^=bottom]{
  z-index: 11;
  inset: 0 4em auto 0 !important;
 
}
.activeStep div{

  color: #E47100 !important;
 
}
.activeStep:before {

  border-left: 40px solid  #FFF7F0 !important;
}

.activeStep path{
  fill: #E47100 !important;
  
    }


    /*
.step {
    width: 200.5px;
    height: 80px;
    position: relative;
    background: rgba(171, 171, 171, 0.1);
    border-radius: 4px;

border-radius: 4px;
transform: rotate(180deg);
transform-origin: 50% 50%;
  }
  .step div{

transform: rotate(180deg);
transform-origin: 55% 48%;
font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 37px;
letter-spacing: -0.3px;

color: #C9C9C9;
  }

  .step path{
fill: #C9C9C9 ;

  }
  .step4 path{
    fill: #C9C9C9 ;
    
      }

  .step:after {
    content: "";
    position: absolute;
    left: -5px;
    bottom: -10px;
    width: 0;
    height: 0;
    border-left: 50px solid white;
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
   
  }
  .step:before {
    content: "";
    position: absolute;
    right: -40px;
    bottom: 0px;
    width: 0;
    height: 0;
    border-left: 40px solid  rgba(171, 171, 171, 0.1);;
    border-top: 40px solid transparent;
    border-bottom: 40px solid transparent;
    
  }

  .step1 {
    width: 200.5px;
    height: 80px;
    position: relative;
    background: rgba(171, 171, 171, 0.1);

border-radius: 4px;
transform: rotate(180deg);
transform-origin: 50% 50%;
  }
  .step1 div{

    transform: rotate(180deg);
    transform-origin: 55% 48%;
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 37px;
    letter-spacing: -0.3px;
    
    color: #C9C9C9;
      }
 
  .step1:before {
    content: "";
    position: absolute;
    right: -40px;
    bottom: 0px;
    width: 0;
    height: 0;
    border-left: 40px solid  rgba(171, 171, 171, 0.1);
    border-top: 40px solid transparent;
    border-bottom: 40px solid transparent;
    
  }

  .step4 {
    width: 200.5px;
    height: 80px;
    position: relative;
    background: rgba(171, 171, 171, 0.1);
border-radius: 4px;
transform: rotate(180deg);
transform-origin: 50% 50%;
  }

  .step4 div{

    transform: rotate(180deg);
    transform-origin: 55% 48%;
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 37px;
    letter-spacing: -0.3px;
    
    color: #C9C9C9;
      }
      .step4:after {
        content: "";
        position: absolute;
        left: -5px;
        bottom: -10px;
        width: 0;
        height: 0;
        border-left: 50px solid white;
        border-top: 50px solid transparent;
        border-bottom: 50px solid transparent;
       
      }
    */

    .processDown{
      display: none;
  }
.step {
  width: 200.5px;
  height: 80px;
  position: relative;
  background: rgba(171, 171, 171, 0.1);
  border-radius: 4px;

border-radius: 4px;
z-index: 1;
}
.step path{
  fill: #C9C9C9 ;
  
    }
    .stepAr path{
      fill: #C9C9C9 ;
      
        }
.step div{

  margin-left: 2em;
font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 37px;
letter-spacing: -0.3px;

color: #C9C9C9;
}

.step path{
fill: #C9C9C9 ;

}
.step4 path{
  fill: #C9C9C9 ;
  
    }

.step:after {
  content: "";
  position: absolute;
  left: -5px;
  bottom: -10px;
  width: 0;
  height: 0;
  border-left: 50px solid white;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
 
}
.step:before {
  content: "";
  position: absolute;
  right: -40px;
  bottom: 0px;
  width: 0;
  height: 0;
  border-left: 40px solid  rgba(171, 171, 171, 0.1);;
  border-top: 40px solid transparent;
  border-bottom: 40px solid transparent;
  
}







.step1 {
  width: 200.5px;
  height: 80px;
  position: relative;
  background: rgba(171, 171, 171, 0.1);

border-radius: 4px;
z-index: 2;
}
.step1 svg{
    margin-top: 0.5em;
}
.step1 div{


  font-family: 'Cairo';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 37px;
  letter-spacing: -0.3px;
  
  color: #C9C9C9;
    }

.step1:before {
  content: "";
  position: absolute;
  right: -40px;
  bottom: 0px;
  width: 0;
  height: 0;
  border-left: 40px solid  rgba(171, 171, 171, 0.1);
  border-top: 40px solid transparent;
  border-bottom: 40px solid transparent;
  
}

.step4 {
  width: 200.5px;
  height: 80px;
  position: relative;
  background: rgba(171, 171, 171, 0.1);
border-radius: 4px;

}

.step4 div{

margin-left: 2em;
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 37px;
  letter-spacing: -0.3px;
  
  color: #C9C9C9;
    }
    .step4:after {
      content: "";
      position: absolute;
      left: -5px;
      bottom: -10px;
      width: 0;
      height: 0;
      border-left: 50px solid white;
      border-top: 50px solid transparent;
      border-bottom: 50px solid transparent;
     
    }

/* ar */

.step1Ar {
  width: 200.5px;
  height: 80px;
  position: relative;
  background: rgba(171, 171, 171, 0.1);

border-radius: 4px;
z-index: 2;
transform: rotate(180deg);
transform-origin: 50% 50%;
}
.step1Ar div{


  transform: rotate(180deg);
  transform-origin: 50% 50%;
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 37px;
  letter-spacing: -0.3px;
  
  color: #C9C9C9;
    }

.step1Ar:before {
  content: "";
  position: absolute;
  right: -40px;
  bottom: 0px;
  width: 0;
  height: 0;
  border-left: 40px solid  rgba(171, 171, 171, 0.1);
  border-top: 40px solid transparent;
  border-bottom: 40px solid transparent;
  
}


.stepAr {
  width: 200.5px;
  height: 80px;
  position: relative;
  background: rgba(171, 171, 171, 0.1);
  border-radius: 4px;

border-radius: 4px;
z-index: 1;
transform: rotate(180deg);
transform-origin: 50% 50%;
}
.stepAr div{

 
  transform: rotate(180deg);
  transform-origin: 55% 50%;
font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 37px;
letter-spacing: -0.3px;

color: #C9C9C9;
}



.stepAr:after {
  content: "";
  position: absolute;
  left: -5px;
  bottom: -10px;
  width: 0;
  height: 0;
  border-left: 50px solid white;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
 
}
.stepAr:before {
  content: "";
  position: absolute;
  right: -40px;
  bottom: 0px;
  width: 0;
  height: 0;
  border-left: 40px solid  rgba(171, 171, 171, 0.1);;
  border-top: 40px solid transparent;
  border-bottom: 40px solid transparent;
  
}


.step4Ar {
  width: 200.5px;
  height: 80px;
  position: relative;
  background: rgba(171, 171, 171, 0.1);
border-radius: 4px;
transform: rotate(180deg);
transform-origin: 50% 50%;
}

.step4Ar div{
  transform: rotate(180deg);
  transform-origin: 55% 50%;

  font-family: 'Cairo';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 37px;
  letter-spacing: -0.3px;
  
  color: #C9C9C9;
    }
    .step4Ar:after {
      content: "";
      position: absolute;
      left: -5px;
      bottom: -10px;
      width: 0;
      height: 0;
      border-left: 50px solid white;
      border-top: 50px solid transparent;
      border-bottom: 50px solid transparent;
     
    }
    .alignV{
      display: flex;
      height: 100%;
      align-items: center;
    }
    .flexSummery .cpMiddle-p1{
margin-bottom: 0;

    }
  
    .itemImg{
    
      height: 20vh;
      width: 10vw;
      background-position: center;
background-repeat: no-repeat;
background-size: cover;
    }

    .cpMiddle-title{
      display: flex;
      justify-content: space-between;
      margin-bottom: 2em;
      align-items: center;
  }
  .cpMiddle-title button{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
   
    letter-spacing: -0.3px;
    color: #E47100;
    border: 1px solid #E47100;
border-radius: 8px;
background: transparent;
width: 8em;
padding: 0.5em 0;
  }
  .cpMiddle-title .space{
    margin: 0 1em; 
  }

  .cpMiddle-title button:hover{
 
    color: white;

background: #E47100;

  }
  .cpMiddle-title p{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 45px;  
    text-align: right;
    letter-spacing: -0.3px;
    color: #331900;
  
 margin-bottom: 0;
  }

  .cpMiddle-cart{
    background: #FBFBFB;
    box-shadow: 4px 5px 30px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
  }
  .cpMiddle-Nav{
   
      padding: 1.4em 0;
      width: 100%;
      margin: 0 !important;
    background-color: white;
  }
  .cpMiddle{
   
    padding: 5em;
    padding-top: 2em;
  
}
  .cpMiddle-Nav p{
  font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 16px;
white-space: nowrap;

letter-spacing: -0.3px;
color: #633235;
margin-bottom: 0;}

.cpPrice{


  font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 30px;

letter-spacing: -0.3px;


color: #331900;

}
.cpMiddle-item{
    padding: 1em;
background-color: #FBFBFB;
text-align: center;
}
.cpMiddle-item .col{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: -0.3px;
    color: #331900;

}
.cpMiddle-center{
    display: flex;
    justify-content: center;
    align-items: center;
   
}
.cpMiddle-p1{
    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 34px;
letter-spacing: -0.3px;
color: #331900;
margin-bottom: 0.5em;
}
.cpMiddle-p2{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: -0.3px;
    color: #828282;
    margin-bottom: 0.5em;
}
.cpMiddle-p3{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;

    letter-spacing: -0.3px;
     color: #633235;
     margin-bottom: 0;
}
.cpMiddle-p4{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;

    letter-spacing: -0.3px;
 color: #828282;
 display:inline-block ;
margin-bottom: 0.2em;
}
.cpMiddle-item span{
  margin: 0 0.5em ;
  margin-bottom: 0.5em;
}
.cpMiddle-btns button{
background: transparent;
border: none;
font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 30px;

letter-spacing: -0.3px;
color: #E47100;
}
.cpMiddle-btns button:hover{

    color: #ff8307;
}

.cpMiddle-btns span{
    margin:0 10px;
}
.cpMiddle-btns p{
    border-left: 1px solid #BEBEBE;
    display: inline-block;
}
.cpMiddle-btn1{
  color: #E47100 !important;
}
.cpMiddle-btn1:hover{
  color: #f88818 !important;
}
.cpMiddle-center button{
  background: rgba(228, 113, 0, 0.25);
border: none;
border-radius: 100%;
font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 45px;
/* identical to box height */
width: 35px;
height: 35px;
text-align: right;
letter-spacing: -0.3px;
 
padding-bottom: 0.2em;
color: #E47100;
display: flex;
justify-content: center;
align-items: center;
}
.cpMiddle-center button:hover{

  background: rgba(228, 113, 0, 0.35);

}
.cartRemove{
  background: transparent !important;
  color:#ACACAC !important;
}
.cartRemove:hover{
  color:#DB095B  !important;
}
.cpMiddle-center p{
margin-bottom: 0;
}
.cpMiddle-quantity{
  
padding-top: 0.2em;
    display: inline-block;
    margin-bottom: 0;
    width: 66px;
height: 34px;
 
color: #4F4F4F;
}
.cpBottom{
display: flex;
align-items: center;
margin-top: 2em;
justify-content: end;
}
.cpBottom-p1{
    font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 20px;

letter-spacing: -0.3px;
color: #331900;
margin-left: 4em;
}
.cpBottom-p1En{
  margin-right: 4em;
  margin-left: 0em;
}
.cpBottom-p2{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
 
    letter-spacing: -0.3px;
  color: #331900;
  margin-bottom: 0;
}
.emptyCart{

  text-align: center;
  height: 400px;

}
.emptyCart p{

  font-family: 'Cairo';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;

  letter-spacing: -0.3px;
color: #331900;
margin-bottom: 1em;
} 

.emptyAlign{
  position: relative;
  top: 35%;
  transform: translateY(-50%);

} 
.emptyCart button{

  font-family: 'Cairo';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 37px;
  
  letter-spacing: -0.3px;
  background: #E47100;
  border-radius: 10px;
  color: #FFFFFF;
  border: none;
  width: 13em;
  padding: 0.3em;
  }
  .emptyCart button:hover{
  
  
    background: #f18417;
  }

.cpBottom-p3{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 10px;
    letter-spacing: -0.3px;
 color: #828282;
text-align: center;
}
.cartNext button{


font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 37px;

letter-spacing: -0.3px;
background: #E47100;
border-radius: 10px;
color: #FFFFFF;
border: none;
width: 13em;
padding: 0.3em;
}
.cartNext button:hover{


  background: #f18417;
}

.cartNext{
margin: 2em 0;
  text-align: end;
}

.cpMiddle-title2{

  
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 45px;  
   
    letter-spacing: -0.3px;
    color: #331900;
  
 margin-bottom: 1.5em;
}
.cpMiddle-options{
    background: #FFFFFF;
box-shadow: 4px 5px 30px rgba(0, 0, 0, 0.1);
border-radius: 12px;

padding: 2em 7em;
}
.cpForm {
font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 26px;
line-height: 30px;
letter-spacing: -0.3px;
color: #3F3D56;
margin-top: 32px;
display: block;
margin-bottom: 32px;
} 
.addCard{
  border-radius: 12px;
background: var(--base-white, #FFF);
width: 80vw;
/* Shadow/2xl */
box-shadow: 0px 24px 48px -12px rgba(16, 24, 40, 0.18);
padding: 32px;
display: inline-block;
margin-top: 72px;
margin-bottom: 100px;
}
.addressInput p{
  display: inline-block;
  margin-bottom: 0 !important;
font-size: 14px;
}
.overFlowElips{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width:57%;
font-size: 14px;
margin-bottom: 0 !important;
margin-top: 0 !important;
}
.addressInput {
color:#828282;
font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 16px;
border-radius: 8px 8px 0px 0px;
background: #FFF;
border: none;
padding: 9px;
cursor: pointer;
text-align: start;
width: 99%;

display: flex;
justify-content: space-between;
align-items: center;
box-shadow: 0px 6px 33px 0px rgba(0, 0, 0, 0.15);
}
.addressInput svg{
 margin-left: 12px;
}
.addressInputEn svg{
  margin-left: 0 !important;
  margin-right: 12px;
}
.addressChoice{
  text-align: start;
  color:  #667085;
/* Text md/Normal */
font-family: 'Cairo';
font-size: 16px;
padding: 24px 10px;
height: 300px;
overflow: scroll;
border-radius: 0px 0px 0px 8px;
border: 1px solid #D0D5DD;
background-color: white ;
position: relative;
z-index: 2;
/* Shadow/xs */
width: 99%;
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.addressChoice .flexBetween{
  align-items: baseline;
}
.flexBetween{
  display: flex;
  align-items: center;
  margin-top: 0;
  justify-content: space-between;
}
.addressChoice p{
  display: inline;
  margin: 0 8px;
 
}
.addressChoice input{
  height: 0;
  width: 0;
}
.addressInput button{
  font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 37px;
min-width: 168px;
white-space: nowrap;
letter-spacing: -0.3px;
background: #E47100;
border-radius: 10px;
color: #FFFFFF;
border: none;
margin: 0;
padding: 5.5px 0;
}
.addressInput button:hover{
  background: #f18417;

}
.addressInput{
position: relative;

}
.orderTitle{
  color: #3F3D56;
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: normal;
  letter-spacing: -0.3px;
  color: #3F3D56;
  
  display: block;
  margin-bottom: 32px;
}
.cpRadio label{
  font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 30px;
letter-spacing: -0.3px;
color: #3F3D56;

display: block;
margin-bottom: 1em;
}

.cpPayment-div2 span{

  font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 30px;

letter-spacing: -0.3px;

color: #4F4F4F;


}
.cpMiddle-Nav p{
  text-align: center;
}
.promo{
display: flex;
justify-content: space-between;
border: none !important;
cursor: default !important;
margin-bottom: 0 !important;
padding: 0 !important;
align-items: center !important;
}
.promo div{
  border: none !important;
  cursor: default !important;
  margin-bottom: 0 !important;
  padding: 0 !important;
display: inline-block !important;
width: 60% !important;
}
.promo button{
  font-family: 'Cairo';
  font-size: 16px;
  font-style: normal;
  letter-spacing: -.3px;
background: #fff;
  border: 1px solid #e47200;
  border-radius: 14px;
  color: #e47200;
  font-weight: 600;
  padding: 0.1em 0.5em;
  text-align: center;
 
width: 30%;
}
.promo button:hover{

  background: #e47200;
  color: white;

}
.continue{

 font-family: 'Cairo';
  font-size: 16px;
  font-style: normal;
  letter-spacing: -.3px;
background: #e47200;
  border: 1px solid #e47200;
  border-radius: 12px;
margin-top: 32px;
  color: white;
  font-weight: 600;
  padding:16px 0;
  width: 297px;
  text-align: center;
 

}
.continue button:hover{

  background: #ec7f12;


}
.cpPayment-div2 input[type="text"]{

  border: 1px solid #E0E0E0;
  border-radius: 10px;

font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 30px;
letter-spacing: -0.3px;
width: 100%;
padding: 10px;
}
.errMsg{
  color: brown !important;
  display: block;
  font-family: 'Cairo';
font-style: normal;
}

.datePicker input{
  border: 1px solid #E0E0E0;
  border-radius: 10px;
font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 30px;
letter-spacing: -0.3px;
cursor: pointer;
}
.datePicker{
  margin-top: 2em;
}

.cpMiddle-options .message{
    width: 100%;
    border: 1px solid #E0E0E0;
border-radius: 10px;
height: 6em;


font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 30px;
letter-spacing: -0.3px;
color: #BEBEBE;
padding:0 1.5em;

padding-bottom: 3em;
}

.cpMiddle-options .cpRadio{
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
 
  letter-spacing: -0.3px;
  vertical-align: middle;
  color: #828282;
 
margin-top: 3em;
}
 .cpRadio label{

margin:0 0.5em;
}



.cpRadio  input[type="radio"] {
	accent-color: #E47100;
  vertical-align: middle;
  width: 1em;
  height: 1em;
  cursor: pointer;
}

/*cartProcess3*/
.cpPayment{

background: #FFFFFF;
box-shadow: 4px 5px 30px rgba(0, 0, 0, 0.1);
border-radius: 12px;
padding: 1.5em;
}

.cpPayment-div2 p{
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  /* identical to box height */
  

  letter-spacing: -0.3px;
  
  /* #633235 */
  
  color: #633235;
  margin-bottom: 1em;
}

.cpPayment-div2 {
  
display: flex;
justify-content: center;}

.cpPayment-div2 div{
  cursor: pointer;
}
.cpPayment-div2 label{
  cursor: pointer;
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  
  letter-spacing: -0.3px;
  
  /* #331900 */
  
  color: #331900;
}
.cpPayment-div2   input[type="radio"] {
	accent-color: #E47100;
  vertical-align: middle;
  width: 0.8em;
  height: 0.8em;
  cursor: pointer;
}

.cpPayment-div2  div{
  border: 1px solid #E0E0E0;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  margin-bottom: 1em;
  width: 80%;

}

.cpPayment-div2  .labelIcon{
 color: #828282;
 margin: 0 0.5em;
}

.cpPayment-div2  .activeChoice{
  border: 1px solid #E47100;

}
.cpPayment-div1{
  background: #F4F4F4;
border-radius: 12px;

}
.cpPayment-div1 .cpPayment-div1-title{
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
 
  letter-spacing: -0.3px;
  color: #633235;
  
}
.cpMoneyBreakdown div{
 display: flex;
 justify-content: space-between;
 align-items: center;

}
.cpPayment-div1 .cpMoney{
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 34px;
  letter-spacing: -0.3px;
  
  color: #331900;
 }
 .cpMoneyBreakdown p{
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.3px;
 color: #828282;

 }
 .cpTotaldiv{
   display: flex;
   justify-content: space-between;
   position: relative;
   top: 50%;
   transform: translateY(50%);
 }
 .cpTotal{
 margin-bottom: 0;
 font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 20px;
letter-spacing: -0.3px;
color: #331900;
text-align: left;
}
.cpCurrency{
  font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 30px;
letter-spacing: -0.3px;
color: #828282;

margin-bottom: 0;
}
.cpTotalP{
  font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 52px;
letter-spacing: -0.3px;
color: #331900;
margin-bottom: 0;
}
/* cartprocess4 */

.cpPaymentDone{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.paymentDone-p1{
  font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 45px;
letter-spacing: -0.3px;
color: #331900;
margin-bottom: 1.5em;
}

.paymentDone-p2{
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 34px;

  letter-spacing: -0.3px;
 color: #828282;
 margin-bottom: 1.5em;
}
.paymentDone-p2 span{

  color: #E47100;
}
.cpPaymentDone button{
  font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 41px;
letter-spacing: -0.3px;
color: #E47100;
border: 1px solid #E47100;
border-radius: 14px;
background-color: white;
padding: 5px 0;
width: 100%;
}
.cpPaymentDone button:hover{

  background-color: #E47100;

color: white;
}

.order-track{
  background: #FFFFFF;
border: 0.5px solid #BEBEBE;
border-radius: 12px;
padding: 2em;
margin: 3em 0;
}

.ot-top{

  display: flex;
  justify-content: space-between;
}

.ot-top p{
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;

  letter-spacing: -0.3px;
  
  color: #633235;
}
.ot-top button{

  font-family: 'Cairo';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 15px;

  letter-spacing: -0.3px;
  border: 1px solid #E47100;
border-radius: 10px;
  color: #E47100;
  background-color: white;
  width: 25%;
display: flex;
justify-content: space-around;
align-items: center;
}
.ot-top button:hover{

  color: white;
  background-color: #E47100;

}
.addOrder .cpMiddle-title2{
    text-align: start;
    margin: 1em 0;
}
.addOrder .cpRadio{
    text-align: start;

}
.cpRadio select{
    cursor: pointer;
    background-color: #f5f2f2;
    border: none;
    border-radius: 9px;
    color: #633235;
    font-family: Cairo;
    font-weight: 300;
    padding: 5px;
 width: 10em;
}
.mobileButtons button{

  font-family: 'Cairo';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 15px;
height: 45px;
  letter-spacing: -0.3px;
  border: 1px solid #E47100;
border-radius: 10px;
  color: #E47100;
  background-color: white;
width: 100%;

justify-content: space-around;
align-items: center;
}
.mobileButtons button:hover{

  color: white;
  background-color: #E47100;

}
.mobileButtons{
display: none !important;
justify-content: space-between;
}

.ot-icon div{
  position: relative;
  top: 50%;
  transform: translateY(-64%);
  height: 7em;

 
  background: #F2F2F2;
border-radius: 10px;
background-size: contain;
background-repeat: no-repeat;
background-position: center;

}
.ot-div1{
  border-bottom: 1px solid #E0E0E0;
}

.ot-address{
  border-bottom: 1px solid #E0E0E0;
}
.ot-summary{
  padding-bottom: 2em;
  border-bottom: 1px solid #E0E0E0;
} 
.ot-summary .rowMagin{

  margin: 2em 0;
}
.ot-details div{
    text-align: start;
}

.ot-summary .cpMiddle-center{
  flex-direction: column;
  justify-content: space-evenly;
}
.ot-addressTitle{
text-align: start;
margin: 0.5em 0;
font-family: 'Cairo';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
 
  letter-spacing: -0.3px;
  
margin-bottom: 60px;
  color: #101828;

}
.ot-addressTitle span{
  margin: 0 1em;
}

.iconDirection{
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.orderCancelled{
  font-family: 'Cairo';
  font-weight: 500;
  font-size: 20px;
}
.ot-addressDetail p{
text-align: start;
  font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 26px;

letter-spacing: -0.3px;



color: #828282;
}



.ot-details p{
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
 margin-bottom: 0.6em;
  letter-spacing: -0.3px;
  
  color: #4F4F4F;

}
.gift-p1{
  font-family: 'Cairo';
    font-size: 20px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: -.3px;
    display: inline-block;
}
.gift-p2{
  color: #828282;
  font-family: 'Cairo';
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 2em;
}
.giftContainer span{
  margin: 0 0.2em;
}
.giftContainer button{
  font-family: Cairo;
    font-size: 18px;
    font-style: normal;
    letter-spacing: -.3px;
  background: #fff;
    border: 1px solid #e47200;
    border-radius: 14px;
    color: #e47200;
    font-weight: 600;
    padding: 0.5em 1em;
    text-align: center;
    margin-top: 1em;
    width: 70%;
    max-width: 360px !important;
}
.giftContainer button:hover{
  background: #e47200;

    color: white;
  
}
.giftContainer{

  background: #FFFFFF;
box-shadow: 4px 5px 30px rgba(0, 0, 0, 0.1);
border-radius: 12px;
margin-top: 2em;
margin-bottom: 5em;
text-align: center;
min-height: 60vh;
padding-bottom: 2em;
}
.liEn{
  direction:ltr ;
  text-align: start;
}

.ot-details span{

  font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 17px;
line-height: 30px;
margin:  0.5em;
letter-spacing: -0.3px;
color: #828282
}


.ot-totalDiv{

display: flex;
justify-content: space-between;
}
.osItem{
  font-family: 'Cairo';
}
.ot-totalDiv p{
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 37px;
 margin-bottom: 0.2em;
  letter-spacing: -0.3px;
  
  color: #4F4F4F;

}
.ot-total {
  padding-top: 2em;
}
.ot-total  .cpTotaldiv{
 border-top: 1.5px solid #D0D5DD;

 padding-top: 20px;
}
.cpTotalP{
  color: #101828 !important;
  /* 16/Medium */
  font-size: 16px !important;
  font-style: normal;
  font-weight: 500;
}
.printInvoice button{
  border: none;
  color: #FFF;
  width: 269.5px;
  padding: 10px 0;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 30px; /* 150% */
  border-radius: 8px;
background:  #E47100;
box-shadow: 4px 4px 10px 0px rgba(228, 113, 0, 0.10);
}
.flexSummery{
  display: flex;
  align-items: center;

}
.ot-total .cpTotaldiv{
  margin-top: 15px;
}
.printInvoice button:hover{
  background:  #f28111;

}
.branchAlign{
 align-items: baseline !important;
}
.productNmbrs{
  display: flex;
  justify-content: space-between;
}
.printInvoice {
  text-align: end;
  margin-top: 30px;
}
.cpCurrency{
  color:  #FF7E00 !important;

font-size: 20px !important;
font-style: normal;
font-weight: 500 !important;
line-height: normal;
}
.ot-totalDiv .ot-value{
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 34px;

  letter-spacing: -0.3px;
  
  color: #331900;
  
}

.offerIcon{
  display: block;
  text-align: center;
  background-color: #E47100;
  padding: 14px 1em;
  border-radius: 4px 4px 0 0;
}
.odderBckg{

  
  height: 100px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.offer{


  font-family: 'Cairo';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 37px;
  
  letter-spacing: -0.3px;
  
  color: #331900;
  
      }
  
      .offerAfter{
  
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 26px;
  
  letter-spacing: -0.3px;
  
  
  color: #E47100;
  
      }
      .tick{
position: absolute;
color: white;
background-color: rgb(56, 162, 102);
border-radius: 100%;
top: 10px;
left: 10px;
      }
      .tickEn{
        left: auto !important;
        right: 10px;
      }
      .activeChoice .labelIcon {
        color: #E47100;
      }
      .offerBefore{
          text-decoration: line-through;
          text-decoration-color:#4F4F4F;
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 26px;
  
  letter-spacing: -0.3px;
  
  color: #aba9a9;
      }
      .offerdetail{
          display: flex;
          justify-content: space-around;
          margin-bottom: 2em;
      }
      .offer-middle{
        font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    
    
    letter-spacing: -0.3px;
    
    color: #E47100;
    
    }
    .delBtn{
      position: absolute;
      left:15px;
      top: 15px;
    }
   
    .mobileCartItem{
      background-color: #FBFBFB;
      position: relative;
      padding: 0.5em 0;
      border-radius: 8px;
    }
    .mobileCartItem .alignImg div{
  
    }
    .quantityNum{
      margin: 0 0.8em;
     padding-top: 0.3em;
     font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 16px;

letter-spacing: -0.3px;

color: #4F4F4F;
    }
    .delBtnEn{
      position: absolute;
      right: 15px;

      left: auto;
    }
    .discount{

      margin: auto;
      width: 50%;
    }
    .basketItemImg{
      width: 100%;
      height: 25vw;
      max-height: 150px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 10px;
    }
    .cartItems{
display: none;
    }
    .callDriver{

      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }




    /*process*/

.activeStep div{

  background: #FFF7F0 !important;
 
 color: #E47100 !important;
 }
 
 .activeStep div:before {
 
   border-left: 40px solid  #FFF7F0 !important;
 }
 
 .activeStep path{
   fill: #E47100 !important;
  stroke:#FFF7F0 ;
     }
     .activeStep ellipse{
        stroke: #e47100;
        fill: #E47100 !important;
           }
 
 .step {
     width: 200.5px;
     height: 80px;
     position: relative;
     background: rgba(171, 171, 171, 0.1);
     border-radius: 4px;
 
 border-radius: 4px;
 z-index: 1;
   }
   .step div{
 
     margin-left: 2em;
 font-family: 'Cairo';
 font-style: normal;
 font-weight: 600;
 font-size: 20px;
 line-height: 37px;
 letter-spacing: -0.3px;
 
 color: #C9C9C9;
   }
 
   .step path{
 fill: #C9C9C9 ;
 
   }
   .step4 path{
     fill: #C9C9C9 ;
     
       }
 
   .step:after {
     content: "";
     position: absolute;
     left: -5px;
     bottom: -10px;
     width: 0;
     height: 0;
     border-left: 50px solid white;
     border-top: 50px solid transparent;
     border-bottom: 50px solid transparent;
    
   }
   .step:before {
     content: "";
     position: absolute;
     right: -40px;
     bottom: 0px;
     width: 0;
     height: 0;
     border-left: 40px solid  rgba(171, 171, 171, 0.1);;
     border-top: 40px solid transparent;
     border-bottom: 40px solid transparent;
     
   }
 
   .step1 {
     width: 200.5px;
     height: 80px;
     position: relative;
     background: rgba(171, 171, 171, 0.1);
 
 border-radius: 4px;
 z-index: 2;
   }
   .step1 svg{
       margin-top: 0.5em;
   }
   .step1 div{
 
   
     font-family: 'Cairo';
     font-style: normal;
     font-weight: 600;
     font-size: 20px;
     line-height: 37px;
     letter-spacing: -0.3px;
     
     color: #C9C9C9;
       }
  
   .step1:before {
     content: "";
     position: absolute;
     right: -40px;
     bottom: 0px;
     width: 0;
     height: 0;
     border-left: 40px solid  rgba(171, 171, 171, 0.1);
     border-top: 40px solid transparent;
     border-bottom: 40px solid transparent;
     
   }
 
   .step4 {
     width: 200.5px;
     height: 80px;
     position: relative;
     background: rgba(171, 171, 171, 0.1);
 border-radius: 4px;
 
   }
 
   .step4 div{
 
   margin-left: 2em;
     font-family: 'Cairo';
     font-style: normal;
     font-weight: 600;
     font-size: 20px;
     line-height: 37px;
     letter-spacing: -0.3px;
     
     color: #C9C9C9;
       }
       .step4:after {
         content: "";
         position: absolute;
         left: -5px;
         bottom: -10px;
         width: 0;
         height: 0;
         border-left: 50px solid white;
         border-top: 50px solid transparent;
         border-bottom: 50px solid transparent;
        
       }
 
 /* ar */
 
 .step1Ar {
     width: 200.5px;
     height: 80px;
     position: relative;
     background: rgba(171, 171, 171, 0.1);
 
 border-radius: 4px;
 z-index: 2;
 transform: rotate(180deg);
 transform-origin: 50% 50%;
   }
   .step1Ar div{
 
   
     transform: rotate(180deg);
     transform-origin: 50% 50%;
     font-family: 'Cairo';
     font-style: normal;
     font-weight: 600;
     font-size: 20px;
     line-height: 37px;
     letter-spacing: -0.3px;
     
     color: #C9C9C9;
       }
  
   .step1Ar:before {
     content: "";
     position: absolute;
     right: -40px;
     bottom: 0px;
     width: 0;
     height: 0;
     border-left: 40px solid  rgba(171, 171, 171, 0.1);
     border-top: 40px solid transparent;
     border-bottom: 40px solid transparent;
     
   }
 
 
 .stepAr {
     width: 200.5px;
     height: 80px;
     position: relative;
     background: rgba(171, 171, 171, 0.1);
     border-radius: 4px;
 
 border-radius: 4px;
 z-index: 1;
 transform: rotate(180deg);
 transform-origin: 50% 50%;
   }
   .stepAr div{
 
    
     transform: rotate(180deg);
     transform-origin: 51% 50%;
 font-family: 'Cairo';
 font-style: normal;
 font-weight: 600;
 font-size: 20px;
 line-height: 37px;
 letter-spacing: -0.3px;
 
 color: #C9C9C9;
   }
 
 .loaderDiv{
    
 }
 
   .stepAr:after {
     content: "";
     position: absolute;
     left: -5px;
     bottom: -10px;
     width: 0;
     height: 0;
     border-left: 50px solid white;
     border-top: 50px solid transparent;
     border-bottom: 50px solid transparent;
    
   }
   .stepAr:before {
     content: "";
     position: absolute;
     right: -40px;
     bottom: 0px;
     width: 0;
     height: 0;
     border-left: 40px solid  rgba(171, 171, 171, 0.1);;
     border-top: 40px solid transparent;
     border-bottom: 40px solid transparent;
     
   }
 
 
   .step4Ar {
     width: 200.5px;
     height: 80px;
     position: relative;
     background: rgba(171, 171, 171, 0.1);
 border-radius: 4px;
 transform: rotate(180deg);
 transform-origin: 50% 50%;
   }
 
   .step4Ar div{
     transform: rotate(180deg);
     transform-origin: 50% 50%;
   
     font-family: 'Cairo';
     font-style: normal;
     font-weight: 600;
     font-size: 20px;
     line-height: 37px;
     letter-spacing: -0.3px;
     
     color: #C9C9C9;
       }
       .step4Ar:after {
         content: "";
         position: absolute;
         left: -5px;
         bottom: -10px;
         width: 0;
         height: 0;
         border-left: 50px solid white;
         border-top: 50px solid transparent;
         border-bottom: 50px solid transparent;
        
       }


  .invoiveLogo{
    width: 100%;
    height: 7em;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  
  }
  .invoiceData div{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .invoiceData{
    padding-bottom: 1em;
    border-bottom: 1px solid #E0E0E0;
}
  
  .invoiceData p{
font-family: 'Cairo';
font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 37px;
    margin-bottom: 0.2em;
    letter-spacing: -0.3px;
    color: #4F4F4F;
  }

  .invoiceData .invoice-value {
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 34px;
    letter-spacing: -0.3px;
    color: #331900;
}
  .invoiceTitle{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 52px;
    letter-spacing: -0.3px;
    color: #331900;
    margin-bottom: 0;
  }
 
  .invoiceTable th{
    text-align: start !important;
  }
  .invoiceTableDiv{
    box-shadow: none !important;
    padding-bottom: 1em;
    border-bottom: 1px solid #E0E0E0;
    border-radius: 0 !important;
  }
 .invoiceTotal1{
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 30px;
  letter-spacing: -0.3px;
  color: #331900 !important;

}
.invoiceTotal2{

  font-family: 'Cairo';
  font-style: normal;
  font-weight: 700 !important;
  font-size: 20px !important;
  letter-spacing: -0.3px;
  color: #331900 !important;
  
}
.caution{
  text-align: start;
  padding-top: 1em ;
}
.invoiceDiv{
  max-width: 500px !important;
  margin: auto;
  display: none;
}
.invoiceTableDiv .table{
  min-width: 0 !important; 
}
.editBtn{
  background: transparent;
  border: none;
}
.editBtn:hover{
color: rgb(236, 70, 133) !important;
}
.cpRadio label{
display: inline;
}
.cpRadio span{

  color: #331900;
  font-weight: 600;
}
.orderSuccess{
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 35px;

  letter-spacing: -0.3px;
  color: #e47100;
  text-align: center;

  align-items: center;
justify-content: center;

}

.shareForm{ 
  border: 1px solid #E0E0E0;
  border-radius: 10px;
  padding: 2em;

  box-shadow: 4px 5px 30px rgba(0, 0, 0, 0.1);

}
.shareForm button{ 
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 34px;
  
  text-align: right;
  letter-spacing: -0.3px;
  
  border: 1px solid #E47100;
  color: #E47100;
  background: white;
  border-radius: 10px;

  padding: 0.5em 3em;
display: block;
margin-top: 50px;
}
.cpRadio input[type="radio"]{
  width: 20px !important;
  height: 20px !important;
  position: relative;
  bottom: -2px;
}
.alignCenter{
  display: flex;
  align-items: center;
}

.shareForm button:hover{ 

  color: white;
  background-color: #e47100;
}
.addBtn{
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 34px;
    letter-spacing: -0.3px;
  border: 1px solid #E47100;
  color: #E47100;
  background: white;
  border-radius: 10px;
  padding: 0.5em 3em;
}


.addBtn:hover{
  color: white;
  background-color: #e47100;
}

.orderDetTop{
  background: #FFF7F1;
  padding: 10px;
  text-align: start;
  margin-bottom: 32px;
}
.orderDetTop p, .orderDetTop a{
  font-family: 'Cairo';
}
.orderDetTop div{
  max-width: 80vw;
  margin: auto;
}
.orderDt1{
  color:  #E47100;

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.orderDt2{
  color:  #101828;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;

}
 .orderDetNav{
  max-width: unset !important;

}
.orderDetNav a{
  font-size: 14px;
  color: #98A2B3;
  text-decoration: none;
}
.orderDetNav a:hover{
  color:#1D2939
}
.orderDetNav .arrow{
  margin: 0 8px;
}
.yourOrders{
  color: #101828;
  text-align: start;

  font-size: 32px;
  font-style: normal;
  font-weight: 600;
 
}
.orderDetType{
  text-align: start;

}
.orderDetContainer{
  padding: 0 80px;
}
.orderDetType p{
  color: #344054;
  text-align: start;
  /* 16/Regular */
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  border-bottom: 2px solid transparent;
  padding-bottom: 3px;
  
}
.orderDetType p:hover{
  color:  #D16700;
  border-bottom: 2px solid #D16700;


}
.orderDetType .activeType{
  color:  #D16700;

  border-bottom: 2px solid #D16700;

}
.detData{

color: #828282 !important;
  font-weight: 500 !important;
}
.orderStatus{
color: #FF7E00 !important;
}
.ordersCard{
  padding: 14px 26px;
  cursor: pointer;
  border-top: 5px solid  transparent;


}
.addOrder .continue{
  padding: 12px 0;
}
.ordersCard div{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ordersCard p{
  color:  #475467;
font-size: 16px;
font-weight: 600;
}
.ordersCard:hover{
  border-radius: 8px;
  border-top: 5px solid  #E47100;
  background:  #FFF3E8;
}
.ordersCardActive{
  border-radius: 8px;
border-top: 5px solid  #E47100;
background:  #FFF3E8;
}
.noPadding{
  padding:  0 !important;
} 
.singleOrderCard{
  border-radius: 8px 8px 0px 0px;
  border-top: 5px solid #FF7E00;
  
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25);
}
.singleOrderNav{
  display: flex;
  align-items: baseline;
}

.singleOrderNav p{
  color:  #D0D5DD;
text-align: center;
white-space: nowrap;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 20px;
margin-top: 24px;
}
.statusActive p{
  color:#101828 !important
}
.singleOrderNav .line{
  width: 30%;
  border-bottom: 3px solid rgba(228, 113, 0, 0.22);;

}

.statusImg{
padding:5px;
width: fit-content;
border-radius: 100%;

margin: auto;
}
.statusImg img{
  padding: 5px;
  border: 2px solid  #D0D5DD;
    border-radius: 100%;
  

}
.statusActive .statusImg{
  background: rgba(228, 113, 0, 0.22);

}
.orderDetType span{
  margin:0 20px;
}
.statusActive img{
  border: 2px solid  #E47100;

}
.orderTop{
  border-bottom:1.5px solid #D0D5DD
}
.orderTop div{
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.orderTopTitle{
  margin-top: 25px;
}
.otderTh{
  color:  #667085 !important;

font-size: 16px !important;
font-style: normal;
font-weight: 400 !important;
line-height: 30px;
} 
.otderTd{
  color: #1D2939 !important;

  font-size: 18px !important;
  font-weight: 500 !important;
}
.orderTopTotal .otderTh{
color: #101828 !important ;
}
.orderTopTotal .otderTd{
  color:#FF7E00 !important;

  font-size: 20px !important;

}
.orderTopTitle p{
  color:  #101828 !important;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
}
.orderTopTotal{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin:  21px 0;
}
.orderSummary{
  padding: 26px 16px;
  margin-bottom: 3em;
}

@media print {
  .navShadow{
    display: none;
 
  }
  .invoiceDiv{
    display: unset;
  }
  .navbar{
    display: none;
  }
  .cartProcess{
    display: none;
  }
}
@media screen and (max-width: 1170px) {

.orderDetContainer{

  padding: 0 2vw;
}}
@media screen and (max-width: 500px) {
  .orderTopTotal{
    margin: 16px 0;
    margin-bottom: 0;
  }
  .orderTopTotal p{
    margin-bottom: 0;
  }

  .orderSummary{
    padding: 16px;
  }
  
  .invoiceTitle{
    font-size: 20px;
    line-height: 0;
    margin-bottom: 1em;
  }
.invoiceData p{
  font-size: 14px;
}
.invoiceData .invoice-value{
  font-size: 13px;
}
.invoiceTotal1{
  font-size: 15px !important;
  line-height:25px;
  margin-bottom: 0;
}
.invoiceTotal2{
  font-size: 18px !important;

}
.invoiceTable{
  min-width: 0 !important;
}
.invoiceDiv{
  padding: 15px !important;
}
}

 
    @media screen and (max-width: 500px){
      .orderDetType span{
        margin:0 15px;
      }
    .cpMiddle-title button{

      font-size: 20px;
  
    }
    .space{
      margin: 0.5em !important;
    }
    .step1{
      position: relative;
      top: 13px;
    }
    .cpMiddle-title {
flex-direction: column;
align-items: start;

    }
    .ot-top p{
font-size: 16px;
    }
    .mobileButtons button{
      font-size: 14px;
    }
    .ot-details {
      font-size: 13px;
    }
    .ot-value{
      font-size: 14px !important;
    }
    .cpTotaldiv{
      margin-top: 1em !important;
      align-items: center;
    }
    .ot-totalDiv p{
      font-size: 14px;
    }
    .cpTotal{
      font-size: 16px !important;
margin-bottom: 0.5em;
    }
    .ot-details span{
      font-size: 13px;
    }
    .ot-addressTitle{
      font-size: 18px;
margin-bottom: 16px;
    }
    .activeStep{
      height: 75px;
    }
    .step1{
height: 0 !important;
    }
    .step4 div{
      position: relative;
      bottom: 10px;
    }
  }
@media screen and (max-width: 665px){
   
  .cpTop{
      align-items: center;
      flex-direction: column;
      transform: scale(1) !important;
    
  }

.processDown{
    display: block;
    margin: 1em 0;
    color: #C9C9C9;
  
}  
.discount{
  width: 80%;
}
  .step div{
 margin: 0 !important;
     }
     .step4 div{
      margin: 0 !important;
          }
  
  .step::before{
  display: none;
  }
  .step1::before{
      display: none;
     }
     .step::after{
      display: none;
     }
     .step4::before{
      display: none;
     }
     .step4::after{
      display: none;
     }





  .stepAr div{
transform-origin: 50% 50%;
     }
     .step4Ar div{
      transform-origin: 50% 50%;
          }

          .step1Ar div{
              transform-origin: 50% 50%;
                  }
          
  
  .stepAr::before{
  display: none;
  }
  .step1Ar::before{
      display: none;
     }
     .stepAr::after{
      display: none;
     }
     .step4Ar::before{
      display: none;
     }
     .step4Ar::after{
      display: none;
     }

}
@media screen and (max-width: 860px){
  .cpTop{
  transform: scale(0.8) ;
  }
}
@media screen and (max-width: 830px){
  .cpMiddle-Nav{
    display: none !important;
  }
  .cpMiddle-item{
    display: none !important;
  }
  .cpMiddle-cart{
box-shadow: none;
background-color: none;

  }
.cartHr{
  display: none !important;
}
.mobileItem{
  display: block !important;
}
}

@media screen and (max-width: 757px){
  .cpMiddle-options{
padding: 2em ;
  }
  .cpMiddle-options .cpRadio{
margin: 1em 0;
  }
 .mobileButtons{
  display: flex !important;
  align-items: center;
 }
 .ot-top button{
   display: none !important;
 }
}
@media screen and (max-width: 500px){
  .cpPayment-div2 img{
    height: 300px;
  }
  .cpPaymentDone button{
    font-size: 16px;
  }
  .cpMiddle-p3{
    margin-bottom: 0;
  }

  .cpMiddle-p4{
    font-size: 13px;
  }
  .cpPayment-div2 div{
    width: 100%;
  }
  .cpMiddle-p3{
    font-size: 13px;
  }
  .cpMiddle-center p{
    font-size: 13px;
  }
  .ot-summary .cpMiddle-center{
    margin-top: 1em;
  }
  .cpMiddle-quantity {
  height: auto;
    display: block;
    text-align: center;}
    .ot-summary .rowMagin{
      margin: 1em 0;
    }
  .cpPayment-div2 input[type="text"] {


    width: 100%;

  }
  .order-track{
    padding: 2em 1em;
  }
  .orderSuccess{
font-size: 16px !important;
line-height: normal !important;

  }
  .shareForm button{
    margin-top: 0 !important;
    font-size: 14px !important;
  }
  .walletImg{
    height: 282px;
    margin-bottom: 0 !important;
  }
  .gift-p1{
    font-size: 16px !important;
  } 
  .gift-p2{
    font-size: 14px !important;
  }
  .giftContainer .freeImg{
    height: 270px;
  }
  .offerAfter{
    font-size: 14px !important;
  }
  .offer-middle{
    font-size: 14px !important;
    margin-bottom: 0;
    line-height: normal;
  }
  .emptyAlign p{
    font-size: 16px !important;
  }
  .emptyAlign button{
    font-size: 16px !important;
  }
  .offerBefore{
    font-size: 14px !important;
  }
}
@media screen and (max-width: 575px){

  .mobileButtons button{
    margin: 0.5em 0;
  }
 
}

@media screen and (max-width: 767px){
  .successMsg div{
    padding: 50px 16px;
  }
  .successMsg{height: 40vh;


  }

  .ot-total,.ot-total .cpTotaldiv{
    padding-top: 0;
  }
  .printInvoice button{
    width: 100%;
  }
  .printInvoice{
  margin-top: 16px;
  }
  .orderTopTitle{
    margin-top: 0;
  }
  .cpTotaldiv{
   transform: inherit;
  }
  .statusActive p{
    font-size: 12px;
  }
  .statusActive .statusImg,  .statusNotActive .statusImg{
margin: 0;
  }
  .statusActive , .statusNotActive{
display: flex;
flex-direction: column;
align-items: center;
  }

  .statusActive img{
    width: 30px;
height: 30px;
  }
}



@media screen and (min-width: 1700px){/*
  .offer{
    font-size: 1.8vw ;
    margin-bottom:1em;
  }
  .gift-p2{
    font-size: 1.3vw ;

  }
  .gift-p1{
    font-size: 1.8vw ;
    position: relative;
    top: 0.3vw;
    margin-bottom: 0.5em;

  }
  .odderBckg{
    height: 8vw;
  }
  .modalBtns button{
    margin-top: 0.5em;
  }
  .offerdetail p{
    font-size: 1.8vw ;

  }
  .offer-middle{
    font-size: 1.5vw !important;

  }
  .datePicker input{
    font-size: 1.5vw !important;
    padding: 0.5em;
  }
  .ot-top p{
    font-size: 1.5vw;
  }
  .ot-top button{
    font-size: 1.4vw;
  padding: 1em 0;
  }
  .ot-details p{
    font-size: 1.2vw ;
 
  }
  .ot-details span{
    font-size: 1.2vw ;
 
  }
  .ot-addressTitle span{
    font-size: 1.3vw ;

  }
  .ot-icon div{
position: relative;
top: 45%;
height:6.5vw
  }
  .ot-addressTitle svg{

width: 2vw;
height: 2vw;
  }
  .cpMiddle-p1{
    font-size: 1.4vw ;

  }
  .cpMiddle-p2{
    font-size: 1.2vw ;
 
  }
  .cpMiddle-p3{
    font-size: 1.3vw ;

  }
  .cpMiddle-p4{
    font-size: 1.2vw ;

  }
  .ot-summary .cpMiddle-center p{
    font-size: 1.2vw ;

  }
  .ot-totalDiv p{
    font-size: 1.3vw ;

  }
  .ot-totalDiv .ot-value{
    font-size: 1.4vw ;

  }
  .cpTotalP{
    font-size: 2vw;
  }
  .cpTotal{
    font-size: 2vw;

  }
  .cpCurrency{
    font-size: 1.3vw;

  } 
  .cpTop{
    transform: scale(1.5);
  }
  .orderCancelled{
    font-size: 1.6vw;
    margin-bottom: 1em;

  }
  .cpMiddle-title p{
    font-size: 1.8vw;
  }
  .cpMiddle-Nav p{
    font-size: 1.3vw;
  }
  .cpMiddle-center p{
    font-size: 1.3vw;
  }
  .cpMiddle-center button {
    font-size: 1.8vw;
    width: 3vw;
    height: 3vw;
  }
  .cpMiddle-quantity{
    height: unset;
  }
  .cpMiddle-center button svg{
    width: 3vw;
    height: 3vw;
  }
  .cpBottom-p1{
    font-size: 2vw;
  }
  .cpMiddle-title button{
    font-size: 1.6vw;
  }
  .cartNext button{
    font-size: 1.5vw;
  }
  .cpBottom-p2{
    font-size: 2vw;
  }
  .cpBottom-p3{
    font-size: 1.3vw;
  }
  .cartProcess{
    min-height: 51vh;
  }
  .cpForm{
    font-size: 1.3vw;
  }
  .cpRadio label{
    font-size: 1.3vw !important;
  }
  .cpRadio input[type="radio"]{
    width: 1.5vw;
    height: 1.5vw;
  }
  .cpMiddle-options .cpRadio .col-md-4 {
    display: flex;
    align-items: center;
  }
  .cpPayment-div2 p{
    font-size: 1.3vw;
  }
  .cpPayment-div2 label{
    font-size: 1.3vw;
    display: flex;
    align-items: center;
  }
  .cpPayment-div2 span{
    font-size: 1.3vw; 
  }
  .cpPayment-div2 .labelIcon{
    width: 1.5vw;
    height: 1.5vw;
  }
  .cpPayment-div2 input[type="radio"]{
    width: 1.5vw;
    height: 1.5vw;

  }
  .cpPayment-div2 input[type="text"]{
    font-size: 1.3vw !important; 
  }
  .promo button{
    font-size: 1.4vw ;  
  }
  .cpPayment-div1 .cpPayment-div1-title{
    font-size: 1.3vw ;  
  }
  .cpMoneyBreakdown p{
    font-size: 1.3vw ;  
  }
  .cpPayment-div1 .cpMoney {
    font-size: 1.4vw ;  
  }
  .emptyCart p{
    font-size: 2vw ;  
  }
  .emptyCart button{
    font-size: 1.6vw ;  
  }
  .emptyCart{
min-height: 55vh;
  }
  .paymentDone-p1{
    font-size: 1.8vw ; 
  }
  .paymentDone-p2{
    font-size: 1.4vw ; 
  }
  .cpPaymentDone button{
    font-size: 1.7vw ; 
  }
  .cpPayment{
    margin: 2em 0;
  }*/
}
.addOrderBckg{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 681.83px;
  max-height: 88vh;
 background-size: cover;
 background-repeat: no-repeat;
background-position: center;
padding-top: 150px;
}
.addOrderTitle{
  color: #FFF;
text-align: center;
font-family: cairo;
font-size: 40px;
font-style: normal;
font-weight: 700;
margin: auto;

letter-spacing: -1.2px;
}


@media screen and (max-width: 991px) {
  .orderSummary{
    margin-bottom: 32px;
  }
  .ordersCard{
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.10);
    padding: 16px 8px;
margin-bottom: 24px;
  }
  .addressInput{
    width: 100%;
  }
  .itemImg{
    width: 17vw;
  }
  
}
@media screen and (max-width: 767px) {
  .ot-addressTitle{
    margin-bottom: 16px;
  }
  .flexSummery{
    justify-content: center;
    width: 100%;
  }
  .itemImg{
    min-width: 150px;
  }
  .addOrderTitle{
max-width: 83vw;
font-size: 30px; 
line-height: 42px; }
.addCard{
  margin-top: 35px;
}
.orderTitle{
  font-size: 26px;

  text-align: start;
}
.addOrderBckg .col-lg-6 {
  padding: 0 !important;
  position: relative;
}
.addressChoice{
  position: absolute;
}
.addressInput p{
  font-size: 14px;
}
.addressInput svg{
  width: 24px;
  height: 24px;
}
.addressInput button{
  font-size: 14px;
  padding: 3px 0;
  min-width: 100px;
}
.continue{
  width: 262px;
  
}
.addCard{
  width: 90vw;
}
}

@media screen and (max-width: 500px){
  
  .datePicker input{
  width: inherit;
  font-size: 14px;

  }
  .addCard{
    padding: 24px;
  }
  .itemImg{
    min-width: 120px;
  }
  .singleOrderNav .statusNotActive, .singleOrderNav .statusActive{
width: 39px;
  }
  .orderDetTop{
    margin-bottom: 24px;
  }
  .orderDetContainer{
    padding: 0 16px;
  }
  .mobileFlex{
    display: flex;
flex-direction: column-reverse;
  }
  .mobileFlex div{
    padding: 16px;

  }
  .mobileOrange{
    background: #FFF7F1;

  }
    .orderDetTop div{
        max-width: unset;
        width: 100%;
    }
    .orderDetTop{
      padding: 0;
      background-color: white;
    }
    .orderDt1{
      font-size: 12px;
      margin-bottom: 0;

    }
    .orderDt2{
      font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: 60px;
margin-bottom: 0;
    }
  .cpTop{
    padding-top: 3em;
  }

.datePickerAr .react-datepicker-popper[data-placement^=top]{
  inset: auto auto 0 0 !important
}
.datePickerAr .react-datepicker-popper[data-placement^=bottom]{

  inset: 0 auto auto 0 !important
}}