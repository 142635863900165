.loginForm select{
    
  background: #FFFFFF;
border-radius: 8px;
font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 30px;

letter-spacing: -0.3px;

color: #ACACAC;
border: none;
padding: 0.5em 10px;
width: 80%;
}
.loginContainer{
  
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 3em ;  
      padding-bottom: 3em ;

}
.navigateSteps svg{
  margin: 0 0.5em;
}
.navigateSteps a {
  font-family: 'cairo';
font-size: 16px;
  text-decoration: none !important;
}
 .navigateSteps a:hover {
color: #E47100 !important;

}
.auth{
 display:flex;
 justify-content:space-between;
 width:80%;
 margin:auto;
 margin-top: 2em;
 align-items: center;
}
.decorationLogin{
  height: 30vw;
  width: 50vw;
  background-repeat: no-repeat;
  bottom: 0;
  left: 0;
  position: absolute;

}
.chickenDecore{
  border-radius: 16px;
  background: rgba(255, 150, 46, 0.25);
  backdrop-filter: blur(13px);
  border: 1px solid white;
  padding: 30px 0;
  height: 90%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.chickenDecore p{
  color: #FFF !important;
  text-align: center;
  padding:0 30px ;
line-height: 65px;
  font-family: Cairo;
  font-size: 42px !important;
  font-weight: 800 !important;
}
.chickenDecore div{
  height: 24vw;
  width: 100%;
  position: relative;
  top: 1vw;
  padding: 0 23px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.decorationLoginEn{
  right: 0;
  left:  auto !important;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.addAuth{
  max-width: 200px;
  padding: 0;
}
.map{
  width: 100%;
  height: 400px!important; 
  margin-bottom: 8px;
} 
.foundUrl{
  height: 44vw;
  padding-bottom: 8vw !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 3em;
  }

.loginForm input{
    
  background: #FFFFFF;
border-radius: 8px;
font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 30px;

letter-spacing: -0.3px;

color: #ACACAC;
border: none;
padding: 8px 10px;
width: 80%;
}


.homeContainer{
  padding: 80px;
  padding-top: 18vh;
  padding-bottom: 0;
}
.homeContainer label{
  color: var(--gray-700, #344054) !important;
text-align: start;
font-family: Cairo;
font-size: 20px !important;
font-style: normal;
font-weight: 500 !important;
line-height: 20px;
margin-bottom: 0 !important;
}
.homeContainer .loginTitle p{
text-align: start;
color: #000;
font-family: Cairo;
font-size: 36px;
line-height: 48px;
font-style: normal;
font-weight: 700;
}
.regContainer .loginPass input{
  padding: 10px 1em !important;
}
.homeContainer svg {
  color: #98A2B3 !important;
}
.regContainer{
padding-bottom: 80px;
}
.regContainer .loginPass input{
  padding: 10px 1em !important;
}
.homeContainer a{
  color: #232020;

font-size: 16px;
font-style: normal;
font-weight: 500;
}
.homeContainer input, .homeContainer select{
  color: var(--gray-400, #98A2B3) !important;
  border-radius: 8px !important;
  width: 100% !important;
border: 1px solid var(--gray-300, #D0D5DD) ;

/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.homeContainer .loginPass{
  width: 100% !important;

}
.disabledLink{
  cursor: not-allowed;
  pointer-events: none;
}
.bckg{
  height: 100%;
  width: 100%;
  padding: 80px;
  padding-top: 130px;
  padding-bottom: 0;

  background: linear-gradient(315deg, #DC6803 0%, #FEC84B 100%);
}

.loginBckg{
  padding: 0 !important;
  position: relative;
}
.loginTxt{
  color:  #475467;
text-align: start;
font-family: Cairo;
font-size: 32px;
margin:   20px  0 40px 0;
font-style: normal;
font-weight: 400;
line-height: 40px; 
}
.homeContainer button{
  width: 100% !important;
  

}
.homeContainer .register p{
  text-align: center;
  font-size: 16px;

}
.login{

    background: #F4F4F4;
border-radius: 12px;
padding: 2em;
overflow: hidden;
width: 100%;
min-height: 56vh !important;
}
#otp div{
    justify-content: end;
    direction: ltr !important;
}

.eye{
    position: absolute;
    left: 10px;
    top: 10px;
    cursor: pointer;
    
    }
    .eyeEn{
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
        
        }
    .loginTitle{
        font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    padding-top: 30px;
    letter-spacing: -0.3px;
    text-align: center;
    color: #420F12;
    
    }
    .authTitle{
      font-family: 'Cairo';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
  
      letter-spacing: -0.3px;
      text-align: start;
      color: #420F12;
      width: 80%;
      margin: auto;
  margin-top: 0;
      margin-bottom: 0;
    }

    .loginTitle p{
margin-bottom: 0em !important;
    }
    .regStep label{
      width: 80%;
margin: auto;
margin-bottom: 1em;
    }
    .regStep span{
      width: 80%;
      margin: auto;
    }
    .loginForm label{
    
        font-family: 'Cairo';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 41px;
      text-align: start;
        letter-spacing: -0.3px;
        
        color: #4F4F4F;
        display: block;
    margin-top: 19px;
    margin-bottom: 0.5em;
    }
    
  .underline{
    font-size: 1px;
    color: transparent;
  }
    .loginForm textarea{
    
      background: #FFFFFF;
  border-radius: 8px;
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  
  letter-spacing: -0.3px;
  
  color: #ACACAC;
  border: none;
  padding: 0.5em 10px;
  width: 80%;
  }

    .forgotInvalid {
      top: 5em!important;
  }
   
    label svg{
      cursor: pointer;
      margin:  0 0.5em;
    }
    label svg:hover{
      color: #E47100 !important;
    }
    select:focus{
    
        border: 2px solid #E47100 !important;
        outline: none !important;
    }
    input:focus{
    
        border: 2px solid #E47100 !important;
        outline: none !important;
    }
    textarea:focus{
    
      border: 2px solid #E47100 !important;
      outline: none !important;
  }
    .loginForm .forgot{
        font-family: 'Cairo';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 37px;
    
    letter-spacing: -0.3px;
    text-decoration-line: underline;
    
    color: #4F4F4F;
    cursor: pointer;
    
    position: absolute;
    right: 0;
    top: 2.8em;
    }
    .loginForm .forgot:hover{
    color: #E47100 ;
    
    }
    
    .loginForm button{
        font-family: 'Cairo';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 37px;
   
        letter-spacing: -0.3px;
        padding: 6.5px 10px;
    width: 80%;
        background-color: #e47100;
    color: white;
    border: none;
    border-radius: 8px;
    }
    .loginForm button:hover{
        background-color: #f28211;
     
    }
    

.foodModal, .modalOverlay {
    width: 100vw;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 11;
}

.modalOverlay {
    background: rgba(49,49,49,0.8);
}
.loginAlign{
  display: flex;
}
.modal-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: white;
    padding: 14px 1em;
    border-radius: 12px;
  
    z-index: 12;

   display: unset;
width: 500px;
height: 571px;
}
.vCenter{
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}
.modal-content img{
width: 100%;
    max-width: 300px;
    height: 300px;
}
.close-modal {
    position: absolute;
    top: -16px;
    right: -6px;
  border: none;
  background: white;
  border-radius: 50%;
  padding: 0 0;
  color: #e47100;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
}

.close-modal:hover {
    color: #f88f25;

}

.loginForm{
  text-align: start;
}
.loginForm span{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 37px;
    color: rgb(193, 22, 22);
    display: block;
    text-align: start;
}
.invalid {

    border: 2px solid rgb(193, 22, 22) !important;
}

.invalid:focus {

    border: 2px solid rgb(193, 22, 22) !important;
    outline: none !important;
}

.modalBody{
    display: flex;
    justify-content: space-between;
    margin: 1em 0;
    }

    .register{
        margin-top: 1em;
    }
    .register{
      text-align: start;
    }
    .successMsg p{
      font-family: 'Cairo';
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      color:#633235
    }
    .successMsg div{
      align-items: center;
      background: rgba(228,113,0,.1);
      border-radius: 24px;
      display: flex;
      justify-content: center;
      padding: 5em;
      box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.05);

      text-align: center;
    
    }
    .successMsg{
      height: 70vh;
      display: flex;
      align-items: center;
    }
    .register p{

        font-family: 'Cairo';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 37px;
      
        letter-spacing: -0.3px;
        
        color: #4F4F4F;
    margin-bottom: 0.2em;
    }
    
    
    .register button{
    
        font-family: 'Cairo';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 37px;
     
        letter-spacing: -0.3px;
        padding: 0.3em 10px;
    width: 80%;
    border: 1px solid #E47100;
        background-color: white;
    color: #E47100;
    
    border-radius: 8px;
    
    }
    
    .register button:hover{
        color: white;
        background-color: #E47100;
    
    }
    .loginImages{
      position: relative;
  }
  
  .loginImages .img1{
  
  position: absolute;
  width: 300px;
  transform: rotate(180deg);
  right:-22px;
  top:-70px;
  }
  .loginImages .img2{
  
      position: absolute;
      width: 480px;
      right: 70px;
      }
      .loginImages .img3{
  
          position: absolute;
          width: 115px;
          right: 30px;
          top: 80px;
          }
  
    .loginImagesAr{
        position: relative;
    }
    
    .loginImagesAr .img1{
    
    position: absolute;
    width: 300px;
   
    left:-21px;
    top:-72px;
    }
    .loginImagesAr .img2{
    
        position: absolute;
        width: 480px;
        left: -90px;
        }
        .loginImagesAr .img3{
    
            position: absolute;
            width: 115px;
            left: 11px;
            top: 61px;
            }
            input[type="checkbox"]{
                accent-color: #e47100;
                width: auto;
                cursor: pointer;
            }
            
            .terms{
               display: inline-block !important;
               margin:0 1em;
            
               cursor: pointer;
               font-family: 'Cairo';
font-style: normal;
font-weight: 400 !important;
font-size: 18px !important;
line-height: 34px;

letter-spacing: -0.3px;

color: #4F4F4F;
            }

            .forgot{

                font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 45px;
letter-spacing: -0.3px;

color: #4F4F4F;
margin-bottom: 0;
            }

            .dontWorry{

font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 37px;

letter-spacing: -0.3px;
margin-bottom: 0;
color: #4F4F4F;
            }

            .passForm input::placeholder{

font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 30px;
letter-spacing: -0.3px;

color: #ACACAC;
            }

            .passForm label{
                font-family: 'Cairo';
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 41px;
        
                letter-spacing: -0.3px;
                
                color: #4F4F4F;
                display: block;
                margin-top: 1em;
                margin-bottom: 0.5em;
                            }

                            .passForm input{
                                border: none;
                                background: #FFFFFF;
border-radius: 8px;
width: 80%;
padding: 0.5em 0.5em;
                            }
                            .foodModal, .modalOverlay {
                              width: 100vw;
                              height: 100%;
                              top: 0;
                              left: 0;
                              right: 0;
                              bottom: 0;
                              position: fixed;
                              z-index: 11;
                           
                          }
                          .foodModal img{
                            margin: auto;
                              z-index: 12;
                              max-width: 90vw;
                          }
            .passAlign{
                text-align: center;

            
            }

            .passAlign img{

             margin-bottom: 0.8em;
            }
            .passAlignImg{
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .passForm button{
                background: #E47100;
border-radius: 8px;
font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 37px;

letter-spacing: -0.3px;

color: #FFFFFF;
border: none;
display: block;
padding: 0.3em 0;
width: 80%;
margin-top: 2em;
            }
            .passForm button:hover{
                background: #f58311;

            }
            .passForm .formBTn{
                display: flex;
                justify-content: center;
            }
.passForm span{

    color: #c11616;
    display: block;
    font-family: Cairo;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 37px;
}
.navigateReg button{
  width: 30%;
  font-size: 16px;
}
.navigateReg{
  margin-top: 2em;
}
.navigateReg span{
  display: inline;
}
.navigateReg .prev{
  border:2px solid #e47100;
  color: #e47100;
  background-color: white;
}
.navigateReg .prev:hover{
  background-color: #e47100;
  color: white;

}
.dataEdit{
  background: #E47100;
border-radius: 8px;
border: none;

font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 37px;

letter-spacing: -0.3px;

color: #FFFFFF;
width: 20%;
}
.dataEdit:hover{
background-color: #f48312;
}

.cancelEdit{
  width: 20%;
  
      border: 1px solid #E47100;
      border-radius: 8px;
  background-color: transparent;
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 37px;
  
  letter-spacing: -0.3px;
  
  color: #E47100;
  
  }
  .cancelEdit:hover{
  background-color: #E47100;
  color: white;
  }
.uploadInp{
  position: relative;
  width: 80%;
  margin: auto;

}
.uploadInp input{
  width: 100%;
  padding: 10px 0.5em;
}

.uploadInp span{
  width: 100%;
}
.uploadInp button{
  position: absolute;
  left: 0.3em;
  color: white !important;
  bottom:0.3em;
  padding: 5px;
  cursor: pointer;
  background:  #E47100;
border: none;
border-radius: 6px;
  pointer-events: none;
}

.uploadInp input{
background: var(--linear, linear-gradient(135deg, #FFF 0%, #F1F1F1 100%));
}
.uploadInpEn svg{
  left: auto ;
  right: 1em ;

}
.loginPass{

    position:relative;
    width:80%;
    display:inline-block;
}
.navigateSteps{
  text-align: start;
}
@media screen and (max-height: 700px){

  .homeContainer{
    padding-top: 12vh;
  }
  .loginForm button{
    margin-top: 2em !important;
  }
}

@media screen and (max-width: 922px){
  .loginAlign{
    align-items: center;
  }
  .loginForm{
margin: auto;
width: 60%;
  } 
  .loginForm input{
    width: 100%;

  }
  .loginForm textarea{
    width: 100%;

  }
  .loginPass{
    width: 100%;
  }
  .loginForm button{
    width: 100%;
  }
    .loginImagesAr{ 
        display: none;

    }
    .register{
 width: 60%;
 margin: auto;
    }
    .register p{
      margin-top: 0.5em;
    }
    .register button{
      width: 100%;
    }
    .loginForm select{
      width: 100%;
    }
    .loginContainer{

        padding: 2em 1em;
     
        padding-top: 5em;
    }
  
}

@media screen and (max-width: 922px){
  .chickenDecore p{
    font-size: 30px !important;
    line-height: 50px;
  }
  .homeContainer{
    padding: 36px;
   
  }
  .loginForm{
    width: 100%;
  }
  .bckg{
    padding: 30px;
    display: flex;
    align-items: center;
  }
  .regContainer{
    padding-top: 80px !important;
  }
}
@media screen and (max-width: 767px){
  .foundUrl{
    padding-bottom: 5vw !important;
}
  .regColor{
    color: #E47100;

  }
  .bckg{
    display: none;
  }
  .homeContainer{
    max-width: 85vw;
    padding: 0;
    margin: auto;
  }
  
  .loginTitle p{
    text-align: center !important;
  }
  .register a{
    text-decoration: none;

  }
}
@media screen and (max-width: 500px){
  .foundUrl{
    padding-bottom: 2.5vw !important;
}
  .homeContainer .loginPass .eye{
    top: 10px !important;
  }
  .loginForm .forgot{
    margin-top: 10px;
    color: #E47100;
  }
  .register{
    width: 100%;
  }
 
 
    .loginTitle{ 
        font-size: 16px;

    }
    .advPaymentReg{
      padding-top: 40px;
    }
   .advPaymentReg .loginTitle{ 
      font-size: 24px;

  }
    .loginForm label{
        font-size: 14px !important;
    }
    .loginForm input{
        font-size: 13px;
    }
    .loginForm textarea{
      font-size: 13px;
  }
    .loginForm button{
        font-size: 14px;
    }
    .register button{
        font-size: 14px;
    }
    .loginForm span{
        font-size: 12px;
    }
  .loginForm .forgot{
    font-size: 13px;
  }
  .loginForm select{
    font-size: 13px;
  }
  .eye{
      top: 10px !important;
  }
  .eyeEn{
    top: 10px !important;
}
  .register p{
    font-size: 13px;
  }
  .forgot{
    font-size: 16px;
  }
  .dontWorry{
    font-size: 14px;
  }
  .passForm label{
    font-size: 14px;
  }
  .passForm button{
    font-size: 14px;
  }
  .otpForm label{
    font-size: 14px !important;
  }
  .otpForm button{
    font-size: 14px !important;
  }
  .resendChangePass{
    font-size: 13px !important;
  }
  .invalidOtp{
    font-size: 12px !important; 
  }
}

.invalid {
  border: 2px solid #c11616!important;
}